@mixin mask(){
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @content;
}

@mixin coverImg(){
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
