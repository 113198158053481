.l-terms-privacy {
  .terms-privacy {
    &__wrapper {
      @include mainContainer();
      display: grid;
      grid-template-columns: 2fr 3fr 1fr;
      gap: rem(40);
      align-items: flex-start;
    }
    &__index {
      position: sticky;
      top: calc(var(--main-header-height) + 50px);
      font-size: rem(18);
      a {
        display: block;
        color: getColor("text-dark");
        margin-bottom: rem(7);
        line-height: 1.3;
        &:target {
          color: green;
          border: 1px solid red;
        }
      }
      ul {
        list-style: decimal;
        padding-left: 1.5rem;
      }
    }
    &__content {
      *[id] {
        &::before {
          content: "";
          display: block;
          height: var(--main-header-height);
          margin-top: calc(var(--main-header-height) * -1);
          visibility: hidden;
          position: relative;
          z-index: -1;
        }
      }
      .content-article {
        margin-bottom: rem(30);
        color: getColor("text-dark");
        font-size: rem(16);
        h3 {
          font-weight: 600;
          font-size: rem(18);
        }
        &__block {
          margin-bottom: rem(20);
          line-height: 1.4;
          a {
            word-break: break-word;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down("lg") {
  .l-terms-privacy {
    .terms-privacy {
      &__wrapper {
        grid-template-columns: 1fr 2fr;
        gap: rem(20);
      }
    }
  }
}

@include media-breakpoint-down("md") {
  .l-terms-privacy {
    .terms-privacy {
      &__wrapper {
        grid-template-columns: 1fr;
      }
      &__index {
        position: static;
      }
    }
  }
}
