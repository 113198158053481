%slide-content {
  flex: 0 0 50%;
  padding-left: rem(75);
  @include media-breakpoint-down(md) {
    flex: 0 0 100%;
    height: 100%;
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
%slide-title {
  font-size: rem(40);
  line-height: rem(50);
  @include media-breakpoint-down(md) {
    margin-top: rem(50);
    font-size: rem(25);
    line-height: rem(30);
  }
  @include media-breakpoint-down(sm) {
    margin-top: rem(20);
  }
}
%slide-body {
  margin-top: rem(25);
  font-size: rem(20);
  line-height: rem(30);
  @include media-breakpoint-down(md) {
    margin-top: 0;
    margin-bottom: rem(40);
    font-size: rem(16);
    line-height: rem(20);
  }
}
@keyframes courses-cards-slider {
  0% {transform: translateX(0%)}
  50% {transform: translateX(calc(-100% + 100vw))}
  100% {transform: translateX(0%)}
}
@keyframes float-top {
  0% {transform: translateY(0px) scale(1);}
  50% {transform: translateY(10px) scale(1.1);}
  100% {transform: translateY(0px) scale(1);}
}
@keyframes float-bottom {
  0% {transform: translate(0) scale(1.1);}
  50% {transform: translate(-5px, -10px) scale(1);}
  100% {transform: translateY(0) scale(1.1);}
}
@keyframes float-middle {
  0% {transform: translate(0, -45%) scale(1.1);}
  50% {transform: translate(10px, -55%) scale(1);}
  100% {transform: translate(0, -45%) scale(1.1);}
}


.c-login-carousel {
  position: relative;
  overflow: hidden;
  .carousel {
    cursor: grab;
    &.active {
      cursor: grabbing;
    }
    &__skip-cta {
      display: none;
      position: fixed;
      bottom: rem(40);
      left: 50%;
      transform: translateX(-50%);
      padding: rem(5) rem(25);
      font-size: rem(14);
      border-radius: rem(20);
      color: getColor(secondary);
      border: 1px solid getColor(secondary);
      z-index: $z-i-main;
      transition: all 0.5s ease-in-out;
      &.gray {
        color: getColor(smokegray);
        border-color: getColor(smokegray);
      }
    }
    &-control-prev,
    &-control-next {
      display: none;
    }
    &-indicators {
      margin-bottom: 0;
      bottom: rem(100);
      z-index: 1;
      li {
        width: rem(8);
        height: rem(8);
        border-radius: 50%;
        margin-right: rem(5);
        margin-left: rem(5);
        background-color: $black;
        opacity: 0.25;
        transition: all 0.6s ease;
        &.active {
          background-color: $white;
          opacity: 1;
        }
      }
    }
    /* SKIP MODAL */
    &__skip {
      display: none;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      transform: translateY(100%);
      transition: transform 0.5s ease-in-out;
      background-color: getColor(secondary);
      z-index: $z-i-modal;
      &.show {
        transform: translateY(0%);
      }
      &__wrapper {
        padding: rem(55) rem(25) rem(25);
      }
      &__close {
        color: getColor(smokegray);
        position: absolute;
        top: 0;
        right: 0;
        padding: rem(15);
        z-index: $z-i-main;
      }
      &__pretitle {
        color: getColor(tertiary);
        font-size: rem(14);
        text-transform: uppercase;
      }
      &__title {
        color: getColor(primary);
        font-size: rem(25);
        margin-top: rem(15);
        margin-bottom: rem(30);
      }
      &__cta {
        display: block;
        width: 100%;
        padding: rem(15);
        border-radius: rem(7);
        text-align: center;
        &-always {
          color: getColor(smokegray);
          border: 1px solid getColor(smokegray);
          margin-bottom: rem(15);
          &:hover {
            text-decoration: none;
            color: getColor(secondary);
            background-color: getColor(smokegray);
          }
        }
        &-now {
          color: getColor(secondary);
          background-color: getColor(primary);
          &:hover {
            text-decoration: none;
            color: getColor(primary);
            background-color: getColor(secondary);
          }
        }
      }
    }
    /* NAVIGATION BAR DESKTOP */
    &__bar {
      display: flex;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: rem(-15);
      justify-content: center;
      border-radius: rem(7);
      background-color: rgba(getColor(dark), 0.4);
      &__item {
        min-width: rem(200);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: rem(15) rem(30) rem(30);
        color: getColor(secondary);
        transition: transform 0.2s ease-in-out;
        &:hover {
          color: getColor(secondary);
          text-decoration: none;
        }
        &__num {
          flex-shrink: 0;
          width: rem(35);
          height: rem(35);
          margin-right: rem(10);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 1px solid getColor(secondary);
        }
        &__text {
          font-size: rem(18);
        }
        &.item--active {
          border-radius: rem(7);
          background-color: getColor(secondary);
          color: getColor(primary);
          transform: translateY(rem(-10));
          .carousel__bar__item__num {
            color: getColor(secondary);
            background-color: getColor(primary);
            border-color: transparent;
          }
        }
      }
      &__cta {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: rem(15) rem(35) rem(30);
        .cta-primary {
          flex-shrink: 0;
          min-width: unset;
          border-radius: rem(20);
          padding: 0 rem(20);
        }
      }
    }
    /* SLIDES */
    &__slide {
      height: calc(100vh - var(--main-header-height));
      display: flex;
      align-items: center;
      padding: rem(20) rem(20) rem(100);
      background-size: cover;
      background-position: center;
      /* SLIDE VIDEO */
      &-video {
        background-image: url("/images/new-hp/new-hp.jpg");
        &__content {
          @extend %slide-content;
          color: getColor(secondary);
        }
        &__main-logo {
          display: none;
          margin-top: rem(40);
          img {
            width: rem(100);
          }
        }
        &__title {
          @extend %slide-title;
        }
        &__play {
          display: none;
        }
        &__body {
          @extend %slide-body;
        }
        &__cta {
          flex: 0 0 50%;
          display: flex;
          justify-content: center;
          &__play {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: getColor(secondary);
            &:hover {
              color: getColor(secondary);
              text-decoration: none;
              cursor: pointer;
            }
            span {
              display: block;
              margin-top: rem(25);
              padding: rem(10) rem(20);
              border-radius: rem(20);
              border: 1px solid getColor(secondary);
            }
          }
        }
      }
      /* SLIDE COURSES */
      &-courses {
        background-image: url("/images/new-hp/bg-courses.jpg");
        padding-right: 0;
        overflow: hidden;
        &__content {
          @extend %slide-content;
        }
        &__title {
          @extend %slide-title;
          color: getColor(primary);
        }
        &__body {
          @extend %slide-body;
          color: getColor(primary);
        }
        &__courses {
          display: none;
          position: relative;
        }
        &__cards {
          display: block;
          position: absolute;
          width: 70vw;
          right: 0;
          height: rem(270);
          overflow: hidden;
          -webkit-mask-image: -webkit-gradient(linear, right top,
            left top, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            position: relative;
            flex: 0 0 50%;
            width: 100%;
          }
        }
        .courses-slider {
          display: flex;
          position: absolute;
          top: 0;
          transform: translateY(-50%);
          animation-name: courses-cards-slider;
          animation-duration: 120s;
          animation-timing-function: linear;
          animation-iteration-count: infinite;
        }
        .course-item {
          margin: rem(10);
          .c-course-card {
            width: rem(360);
            height: rem(250);
            .card__anchor {
              cursor: auto;
            }
            .card__img {
              height: 100%;
            }
            .card__badge {
              display: none;
            }
            .card__title {
              width: 100%;
              text-align: left;
              font-size: rem(20);
            }
          }
        }
      }
      /* SLIDE RELATORS */
      &-relators {
        background-image: url("/images/new-hp/bg-relators.jpg");
        justify-content: space-between;
        &__content {
          @extend %slide-content;
          color: getColor(secondary);
        }
        &__title {
          @extend %slide-title;
        }
        &__body {
          @extend %slide-body;
        }
        &__relators {
          display: none;
          position: relative;
          img {
            width: 100%;
            border-radius: 50%;
          }
        }
        &__logos {
          margin-top: rem(50);
          display: flex;
          align-items: center;
          img {
            margin-right: rem(20);
          }
        }
        &__picture {
          flex: 0 0 45%;
          display: block;
          position: relative;
          max-height: unset;
          height: 55%;
          margin-left: rem(50);
          img {
            width: 100%;
            border-radius: 50%;
          }
        }
        .relators-graduation {
          position: absolute;
          width: calc(100% / 3);
          top: 0;
          right: 50%;
          transform: translate(-10px, 10px);
        }
        .relators-paper {
          position: absolute;
          width: calc(100% / 3.5);
          right: 0;
          bottom: 50%;
        }
        .relators-suitcase {
          position: absolute;
          width: calc(100% / 4);
          top: 50%;
          right: 50%;
        }
        .relators-img-top {
          position: absolute;
          width: calc(100% / 3.5);
          top: 0;
          left: 50%;
          animation: float-top 6s ease-in-out infinite;
        }
        .relators-img-middle {
          position: absolute;
          width: calc(100% / 3.5);
          left: 0;
          top: 50%;
          transform: translate(0, -45%);
          transform: scale(1.1);
          animation: float-middle 7s ease-in-out infinite;
        }
        .relators-img-bottom {
          position: absolute;
          width: calc(100% / 3.5);
          bottom: 0;
          right: 25%;
          transform: scale(1.1);
          animation: float-bottom 6s ease-in-out infinite;
        }
      }
      /* SLIDE DEGREE */
      &-degree {
        background-image: url("/images/new-hp/bg-degree.jpg");
        &__content {
          @extend %slide-content;
          color: getColor(secondary);
        }
        &__title {
          @extend %slide-title;
        }
        &__text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        &__crown {
          display: none;
          justify-content: center;
          align-items: center;
        }
        &__cta-desktop {
          display: block;
          margin-top: rem(60);
          color: getColor(secondary);
          padding: rem(15) rem(30);
          border-radius: rem(7);
          border: 1px solid getColor(secondary);
          &:hover {
            color: getColor(primary);
            background-color: getColor(secondary);
            text-decoration: none;
          }
        }
        &__cta-mobile {
          display: none;
          color: getColor(secondary);
          padding: rem(15) rem(30);
          border-radius: rem(50);
          border: 1px solid getColor(secondary);
          &:hover {
            color: getColor(secondary);
            text-decoration: none;
          }
        }
        &__auth {
          display: none;
          width: 100%;
          border-top: 1px solid getColor(secondary);
          a {
            width: 100%;
            color: getColor(secondary);
            padding: rem(15) 0;
            &:hover {
              color: getColor(secondary);
              text-decoration: none;
            }
          }
        }
        &__picture {
          flex: 0 0 50%;
          display: flex;
          justify-content: center;
          color: getColor(secondary);
        }
        .crown-bg {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: rem(90);
          border-radius: 50%;
          background-color: rgba(#FFE594, 0.15);
        }
        .crown-svg {
          width: rem(300);
          height: rem(300);
        }
        .crown-bar {
          position: absolute;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 105%;
          bottom: 15%;
          font-size: rem(16);
          padding: rem(6) rem(15);
          border-radius: rem(25);
          background: linear-gradient(to right, #6A9F25, #3A6116);
          border: 4px solid getColor(secondary);
          .percentage {
            font-size: rem(20);
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .c-login-carousel {
    height: 100%;
    .carousel {
      height: 100%;
      &__skip-cta {
        display: block;
        &.hide {
          display: none;
        }
      }
      &-inner,
      &-item {
        height: 100%;
      }
      &-control-prev,
      &-control-next {
        display: flex;
        align-items: flex-end;
        &.hide {
          display: none;
        }
        &-icon {
          margin-bottom: rem(205);
        }
      }
      &-indicators {
        display: flex;
        bottom: rem(200);
        &.hide {
          display: none;
        }
      }
      &__skip {
        display: block;
      }
      &__bar {
        display: none;
      }
      &__slide {
        height: 100%;
        padding: rem(70) rem(25) rem(60);
        &:not(&-video) {
          background-position: 92% 50%;
        }
        /* SLIDE VIDEO */
        &-video {
          &__main-logo {
            display: block;
          }
          &__title {
            font-size: rem(30);
            line-height: rem(35);
          }
          &__play {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin-top: rem(25);
            svg {
              height: rem(55);
            }
          }
          &__cta {
            display: none;
          }
        }
        /* SLIDE COURSES */
        &-courses {
          &__content {
            width: 100%;
          }
          &__body {
            color: getColor(smokegray);
          }
          &__courses {
            display: block;
            height: rem(320);
            margin-bottom: rem(50);
            .courses-slider {
              .course-item {
                .c-course-card {
                  width: rem(430);
                  height: rem(300);
                }
              }
            }
          }
          &__cards {
            display: none;
          }
          .courses-slider {
            margin: 0 rem(-25);
            transform: translateY(0);
            animation-duration: 60s;
          }
        }
        /* SLIDE RELATORS */
        &-relators {
          &__content {
            width: 100%;
            align-items: center;
          }
          &__relators {
            display: block;
            width: rem(400);
            height: rem(350);
          }
          &__logos {
            display: none;
          }
          &__picture {
            display: none;
          }
          .relators-graduation {
            width: rem(150);
          }
          .relators-paper {
            width: rem(130);
          }
          .relators-suitcase {
            width: rem(120);
          }
          .relators-img-top,
          .relators-img-middle,
          .relators-img-bottom {
            width: rem(130);
          }
        }
        /* SLIDE DEGREE */
        &-degree {
          padding: 0;
          &__crown {
            flex: 0 0 50%;
            display: flex;
          }
          &__text {
            flex: 0 0 50%;
            justify-content: space-between;
            align-items: center;
          }
          &__title {
            padding: 0 rem(20);
          }
          &__cta-desktop {
            display: none;
          }
          &__cta-mobile {
            display: block;
          }
          &__auth {
            display: flex;
          }
          &__picture {
            display: none;
          }
          .crown-svg {
            width: rem(110);
            height: rem(110);
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .c-login-carousel {
    .carousel__slide {
      &-courses {
        &__courses {
          height: rem(250);
          .courses-slider {
            .course-item {
              .c-course-card {
                width: rem(330);
                height: rem(230);
              }
            }
          }
        }
      }
      &-relators {
        &__relators {
          margin-bottom: rem(50);
          width: rem(325);
          height: rem(250);
        }
        .relators-graduation {
          width: rem(120);
        }
        .relators-paper {
          width: rem(110);
          transform: translateY(5px);
        }
        .relators-suitcase {
          width: rem(100);
        }
        .relators-img-top,
        .relators-img-middle,
        .relators-img-bottom {
          width: rem(100);
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .c-login-carousel {
    .carousel__slide {
      &-relators {
        &__picture {
          height: 60%;
        }
      }
    }
  }
}

// quick fixes for iPhone 5
@media screen and (max-width: 320px),
      screen and (max-height: 650px) {
  .c-login-carousel {
    .carousel__slide {
      &-video {
        &__main-logo {
          margin-top: 0;
          img {
            width: rem(75);
          }
        }
      }
      &-courses {
        &__title {
          margin-top: 0;
        }
      }
      &-relators {
        margin-top: 0;
      }
    }
  }
}
