.c-modules {
  .c-area-info,
  .c-area-info--fancy,
  .c-progressbar {
    @include mainContainer();
  }
  .c-infobar {
    margin-top: 0;
  }
  .modules {
    &__info {
      @include mainContainer();
      padding-top: rem(25);
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      &__text {
        padding-bottom: rem(25);
      }
      &__title {
        margin-bottom: rem(10);
        font-size: rem(30);
        color: getColor('primary');
      }
      &__switch {
        display: flex;
      }
      &__course,
      &__podcast {
        display: block;
        padding-bottom: rem(20);
        position: relative;
      }
      &__course {
        margin-right: rem(20);
      }
      &__icon {
        width: rem(50);
        height: rem(50);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: getColor('light');
        background-color: getColor('medium');
        &.active {
          background-color: getColor('primary');
          &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 6px;
            left: 0;
            bottom: -4px;
            background-color: getColor('primary');
          }
        }
      }
    }
    &__content {
      padding: rem(25) 0;
      margin-bottom: rem(25);
      &.external {
        background-color: getColor('light');
        border-top: 2px solid getColor('lightgray');
      }
    }
    &__courses,
    &__podcast,
    &__exam {
      @include mainContainer();
    }
    &__courses {
      .c-infobar {
        margin-bottom: rem(25);
      }
    }
    &__duration {
      margin-bottom: rem(25);
      &__time {
        color: getColor('primary');
      }
    }
    &__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: rem(35) rem(25);
      &__item {
        .c-course-card {
          &[data-variant='exam'] {
            .card {
              &__img-content {
                padding: rem(20);
                justify-content: space-between;
              }
              &__exam {
                flex-direction: column;
                align-items: flex-start;
                flex-grow: 1;
                &__info__text {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    &__exam {
      margin-bottom: rem(50);
      .card__bg-fallback {
        i {
          width: 18%;
        }
      }
    }
  }
}

@include media-breakpoint-down('lg') {
  .c-modules {
    .modules {
      &__list {
        grid-template-columns: repeat(2,1fr);
      }
    }
  }
}

@include media-breakpoint-down('md') {
  .c-modules {
    .modules {
      &__info {
        flex-direction: column;
        align-items: center;
        &__text {
          width: 100%;
        }
        &__course {
          margin-right: rem(30);
        }
        &__icon {
          width: rem(65);
          height: rem(65);
          svg {
            width: rem(30);
            height: rem(30);
          }
        }
      }
      &__content {
        margin-bottom: 0;
      }
      &__list {
        grid-template-columns: 1fr;
      }
    }
  }
}

@include media-breakpoint-down('sm') {
  .c-modules {
    .modules__courses {
      .c-infobar {
        margin-bottom: 0;
      }
    }
  }
}
