.page-forum {
  .home-course__item-column {
    padding: 0;
    .home-course__item {
      position: relative;
      background-color: $light;
      margin: rem(5) 0;
      padding: rem(30);
      border-radius: 5px;

      &-report {
        position: absolute;
        font-size: rem(12);
        top: 10px;
        right: 10px;
        color: $dark;
      }

      &-user {
        color: $dark;
        font-size: rem(14);
      }

      &-description {
        margin: rem(10) 0;
        font-size: rem(16);
      }

      &-answer {
        color: $dark;
        font-size: rem(14);

        a {
          margin-left: rem(10);
          font-weight: 600;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}
