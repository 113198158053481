$contentMaxWidth: 800px;

.course {
  &-main {
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow-x: hidden;

    &__container {
      @extend .container;
    }

    &__hero {
      display: flex;
      align-items: center;
      margin-bottom: 3rem;

      @include media-breakpoint-down("md") {
        flex-wrap: wrap;
      }

      &__image {
        @extend .img-fluid;
        max-width: 140px;
        width: 100%;

        &-wrapper {
          margin-right: 2rem;
          text-align: center;

          @include media-breakpoint-down("md") {
            flex: 0 0 100%;
            margin-right: 0;
            margin-bottom: 1rem;
          }
        }
      }

      &__title {
        color: $primary;
        font-size: 44px;
        font-weight: 600;
        margin: 0 auto 0 0;

        @include media-breakpoint-down("md") {
          flex: 0 0 100%;
          font-size: 32px;
          margin-bottom: 1rem;
        }

        &-ordinal {
          &::after {
            content: " \2022";
          }
        }
      }

      &__stats {
        color: #949494;
        font-size: 28px;
        font-weight: 600;
        text-transform: uppercase;

        @include media-breakpoint-down("md") {
          flex: 0 0 100%;
          font-size: 20px;
          margin-bottom: 1rem;
        }
      }
    }

    &__content {
      max-width: $contentMaxWidth;
      margin: 0 auto;
    }

    &__image {
      @extend .img-fluid;
      width: 100%;

      &-wrapper {
        padding: 1rem;
        background-color: $white;
      }
    }

    &__description {
      padding: 3rem 0;
    }

    &__instruction {
      color: $secondary;
      border: 2px solid $secondary;
      padding: 3rem 2rem;
      margin-bottom: 3rem;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }

    &__button {
      color: $white;
      max-width: 380px;
      margin: 0 auto;
      padding: 1.5rem 1rem;

      &--disabled {
        border-color: #ccc !important;
        background-color: #ccc !important;
      }

      &-wrapper {
        padding: 2rem 0;
        display: flex;
        justify-content: space-between;
        max-width: $contentMaxWidth;
        margin: 0 auto;
        flex-direction: column;
        [class*="cta"] + [class*="cta"] {
          margin-top: 1em;
        }
        &__repeat{
          order: -1;
          margin-bottom: 1em;
        }
      }
    }
  }

  &__hero {
    @extend .container;
    background: white;
    border-bottom: $bigBordersSize solid $light;
    margin-bottom: 3rem;
    padding-bottom: 0.5rem;

    @include media-breakpoint-down("md") {
      flex-wrap: wrap;
    }

    &-wrapper {
      margin-bottom: 2rem;
    }

    &__image {
      @extend .img-fluid;
      max-width: 100px;
      width: 100%;

      &-wrapper {
        margin-bottom: 1rem;
        margin-right: 2rem;
      }
    }

    &__title-wrapper {
      text-align: center;
      padding: rem(35) 0 rem(20) 0;
    }

    &__pretitle {
      color: $secondary;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: rem(15);
    }

    &__title {
      color: $primary;
      font-size: rem(44);
      font-weight: lighter;
      &::first-letter {
        text-transform: capitalize;
      }

      &-ordinal {
        &::after {
          content: " \2022";
        }
      }
    }
  }
  &__container {
    @extend .container;
  }

  &-article {
    &__wrapper {
      @extend .container;
    }
    &__row {
      display: flex;
      flex-wrap: wrap;
    }

    &__col {
      &--step {
        @extend .col-lg-2;
        display: none;
      }

      &--content {
        flex: 1;
      }
    }

    &__content {
      max-width: $contentMaxWidth;
      margin: 0 auto;
    }

    &__title {
      color: $primary;
      font-size: $h4-font-size;
      font-weight: $font-weight-bold;
      margin-bottom: 2rem;
      line-height: 1.8rem;
    }

    &__editorial,
    &__description {
      p {
        line-height: 1.2rem;
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }

    &__video {
      width: 100%;
      padding-top: 56.25%; /* Fixed 16/9 ratio */
      position: relative;

      iframe {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__image {
      @extend .img-fluid;
      width: 100%;

      &-wrapper {
        padding: 1rem;
        margin-bottom: 3rem;
        background-color: $white;
      }
    }
  }

  &-slide {
    &__content {
      max-width: $contentMaxWidth;
      margin: 0 auto;
      font-size: 0.9rem;
      line-height: 1.4rem;
    }

    &__title {
      color: $primary;
      font-size: 40px;
      font-weight: 400;
      margin-bottom: 3rem;
      line-height: 2.2rem;
    }

    &__video {
      width: 100%;
      padding-top: 56.25%; /* Fixed 16/9 ratio */
      position: relative;
      margin: 3rem 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@include media-breakpoint-up("lg") {
  .course-main__button-wrapper {
    flex-direction: row;

    [class*="cta"] + [class*="cta"] {
      margin-top: 0;
    }
    &__repeat{
      order: 0;
      margin-bottom: 0;
    }
  }
  .course-article {
    &__col--step {
      display: block;
    }
    &__col--content {
      padding: 2rem 5rem;
    }
    &__step {
      position: -webkit-sticky;
      position: sticky;
      top: calc(var(--main-header-height) + 1rem);
      font-size: 1em;
      color: $dark;
      ul {
        padding: 0;
      }
      &__item {
        padding: 0.3em 0;
        list-style: none;
        margin: 0;
        &--active {
          font-weight: $font-weight-bold;
          color: initial;
        }
      }
    }
  }
}

.video-wrap {
  width: 100%;
  padding-top: 56.25%; /* Fixed 16/9 ratio */
  position: relative;

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}
