.downloads-page {

  .hero-title,
  .hero-description {
    max-width: 470px;
  }

  .hero-title {
    margin-bottom: rem(25);
  }

  .hero-user-content {
    padding-top: rem(25);
    padding-bottom: rem(40);
  }
}

.downloads {

  &-container {
    @extend .container;
    padding-bottom: rem(60);
  }

  &-row {
    @extend .row;
  }

  &-content {
    @extend .col-lg-6;
    display: flex;
    flex-direction: column;
    margin-top: rem(25);

    &:first-child {
      margin: 0;
    }
  }
}

@include media-breakpoint-up('lg') {
  .downloads-page {
    .hero-title {
      margin-bottom: rem(18);
    }

    .hero-user-content {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .downloads {

    &-container {
      padding-bottom: rem(100);
    }

    &-content {
      margin: 0;

      &:nth-child(odd) {
        padding-right: rem(45);
      }

      &:nth-child(even) {
        padding-left: rem(45);
      }
    }
  }
}