//Font weights
$font-w-light: 300;
$font-w-regular: 400;
$font-w-semibold: 600;
$font-w-bold: 700;

// Proxima Nova
@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/proxima-nova/ProximaNova-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/proxima-nova/ProximaNova-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/proxima-nova/ProximaNova-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/proxima-nova/ProximaNova-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../fonts/proxima-nova/ProximaNova-Extrabold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

// Segoe UI
@font-face {
  font-family: 'Segoe UI';
  src: url('../../fonts/segoe-ui/SegoeUI-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('../../fonts/segoe-ui/SegoeUI-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('../../fonts/segoe-ui/SegoeUI-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('../../fonts/segoe-ui/SegoeUI-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('../../fonts/segoe-ui/SegoeUI-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('../../fonts/segoe-ui/SegoeUI-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
