.hero-general {

  &-container {
    @extend .container;
    @extend .hero-dot-pattern-v2;
    position: relative;
    padding-top: rem(78);
    padding-bottom: rem(45);

    &::after {
      width: 100%;
    }
  }

  &-row {
    @extend .row;
  }

  &-col-12 {
    @extend .col-12;
  }

  &__logo {
    position: absolute;
    top: 50%;
    z-index: -1;

    width: 240px;
    right: 0;
    transform: translate(0, -50%);
  }

  &__image {
    width: 300px;
    height: 265px;
    margin-bottom: rem(-20);
  }

  &-flex-between {
    display: flex;
    flex-direction: column;
  }

  &-flex-start {
    display: flex;
    flex-direction: column;
  }
}

@include media-breakpoint-up('lg') {
  .hero-general {
    &-container {
      margin-top: rem(10);
      padding-top: rem(160);
      padding-bottom: rem(85);

      &::after {
        width: 100vw;
      }
    }

    &__logo {
      width: 570px;
      transform: translate(200px, -50%);
    }

    &__image {
      margin-right: rem(55);
      margin-bottom: rem(-50);
    }

    &-flex-between {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &-flex-start {
      padding: 0 rem(70);
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      border-bottom: thin solid #949494;
    }
  }
}