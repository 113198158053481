.form {
  &-step {
    display: flex;
    flex-direction: column;
    color: #BEBEBE;
    margin-bottom: rem(20);

    &__number {
      display: none;
      font-size: rem(100);
      font-weight: 200;
    }

    &__text {
      display: flex;
      font-size: rem(22);
      min-width: 150px;
    }
  }
}

@include media-breakpoint-up('lg') {
  .form {
    &-step {
      margin: rem(15) rem(30);

      &__number {
        display: block;
      }

      &__text {
        flex-direction: column;
        font-size: rem(24);
      }
    }
  }
}
