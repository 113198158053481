.c-login-relators-box {
  overflow: hidden;
  background-color: getColor(secondary);
  .relators-box {
    &__wrapper {
      padding: rem(50) 0 rem(50) calc(#{var(--main-container-pad-x)} * 1px);;
      display: flex;
      align-items: center;
    }
    &__info {
      flex: 0 0 30%;
      line-height: 1.2;
      color: getColor(text-gray);
    }
    &__title {
      font-size: rem(25);
      margin-bottom: rem(25);
    }
    &__cards {
      margin-left: rem(50);
      display: flex;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      transition: all 0.2s;
      user-select: none;
      touch-action: none;
      cursor: grab;
      &.active {
        transform: scale(1.025);
        cursor: grabbing;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &__card {
      flex-shrink: 0;
      padding-right: rem(30);
      width: rem(180);
      font-weight: 600;
      &__img {
        width: rem(150);
        height: rem(150);
        border-radius: 50%;
      }
      &__name {
        margin-top: rem(15);
        color: getColor(primary);
        text-align: center;
      }
      &__skill {
        margin-top: rem(10);
        color: getColor(tertiary);
        text-align: center;
      }
    }
  }
}
