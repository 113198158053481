.hero-user {
  &-bg {
    background: $white;
  }

  &-container {
    @extend .container;
    position: relative;
    padding: rem(30);

    &::after {
      content: none;
      z-index: 2;
    }
  }

  &-row {
    @extend .row;
  }

  &-content,
  &-logo {
    @extend .col-lg-6;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-logo {
    position: relative;
    display: flex;
    justify-content: center;
    order: 2;

    margin-top: rem(10);
    padding-top: rem(24);
    padding-bottom: rem(30);

    svg {
      height: 100%;
    }

    &::after {
      left: 50%;
      width: 100vw;
      z-index: -1;
      transform: translateX(-50%);
    }

    &__image {
      z-index: 1;
      width: 320px;
    }

    &__shape {
      position: absolute;
      width: 178px;
      top: 40px;
      left: 50%;
      z-index: 3;
      transform: translateX(-60%);
    }
  }
}

@include media-breakpoint-up("lg") {
  .hero-user {
    &-container {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      // margin-top: rem(15);
      // padding-top: rem(65);
      // padding-bottom: rem(55);

      &::after {
        content: "";
      }
    }

    &-content {
      order: 1;
    }

    &-logo {
      justify-content: flex-start;
      max-height: 360px;
      padding: 0;
      padding-left: rem(30);
      margin: 0;

      &::after {
        content: none;
      }

      &__image {
        width: unset;
      }

      &__shape {
        width: 330px;
        top: 60px;
        left: 140px;
        transform: translateX(0);
      }
    }

    &-cta {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
