.l-quiz {
  &__intro {
    margin-bottom: rem(50);

    &__title {
      color: $primary;
      text-transform: uppercase;
      font-size: rem(18);
      font-weight: bold;
      margin-bottom: rem(25);
    }

    &__desc {

    }
  }
  &__question {
    &__text {
      padding: rem(25) 0;

      &__number {
        font-size: rem(20);
        font-weight: bold;
      }
    }

    &__answer {
      width: 100%;
      cursor: pointer;

      &__wrapper {
        position: relative;

        display: flex;
        align-items: center;

        border-radius: rem(15);

        padding: rem(16);
      }

      &--correct &__correct {
        display: flex;
        color: $secondary;
      }

      &--wrong &__wrong {
        display: flex;
        color: $primary;
      }

      &__correct,
      &__wrong {
        display: none; // flex
        margin-left: auto;

        width: rem(32);
        height: rem(32);

        background: $light;
        border-radius: 50%;

        justify-content: center;
        align-items: center;
      }

      &__input {
        opacity: 0;
        width: 0;

        &:checked {
          & ~ .l-quiz__question__answer__bg {
            background: $primary;
          }

          & ~ .l-quiz__question__answer__checkbox {
            background-color: $light;

            & svg {
              display: block;
            }
          }
        }
      }

      &__bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        width: 100%;
        height: 100%;

        background: $dark;
        border-radius: rem(15);
      }

      &__checkbox {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        min-width: rem(32);
        height: rem(32);

        border: solid 1px $medium;
        border-radius: rem(10);

        & svg {
          display: none;
        }
      }

      &__text {
        color: $light;
        font-weight: bold;
        padding-left: rem(16);
      }

      &__submit {
        color: $light;
        background: $primary;
        border-radius: rem(15);
        padding: rem(16) rem(32);
        font-weight: bold;
        border: none;
      }
    }
  }
}
