@import "./abstract/_index";
@import "./root/_index";
@import "./utility/_index";
@import "./base/index";
@import "vendors/driver-js-overwrite";
@import "./component/_index";
@import "./layout/_index";

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Proxima Nova";
  font-size: 1rem;
  font-weight: normal;
  background-color: $white;
  color: $black;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  background: url("/images/dot-pattern.svg") repeat;
}

.content {
  flex-grow: 1;
}

.pagination {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;

  .page {
    &-item {
      margin: 0 0.25rem;

      &.disabled,
      &.active {
        color: gray;
      }
    }

    &-link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.5rem;
      height: 2.5rem;
      border: thin solid transparent;
      border-radius: 50%;
      transition: 300ms;

      &[href]:hover {
        text-decoration: none;
        border-color: darkgray;
        background-color: lightgray;
      }
    }
  }
}
