.admin-table {
  @extend .container;
  position: relative;
  padding-top: rem(40);
  padding-bottom: rem(60);

  &__arrow-left,
  &__arrow-right {
    display: none;
  }

  &__title {
    margin-bottom: rem(30);
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      color: $primary;
      font-size: rem(25);
      font-weight: 600;
    }
  }

  &__filter {
    display: flex;
    align-items: center;

    border: solid 1px $medium;
    border-radius: rem(25);
    padding: rem(2) rem(15);
    margin-bottom: rem(15);

    &__icon {
      color: $medium;
      width: rem(20);
      height: rem(20);
      margin-right: rem(15);
    }

    input[name="filter"] {
      display: block;
      width: rem(300);
      flex: 1;
      border: none;
      padding: rem(10) rem(5);
      margin: 0;
    }
  }

  &__block {
    margin-bottom: rem(10);
  }

  &__row {
    display: grid;
    overflow: hidden;
    z-index: 1;
    &[data-col="1"] {
      grid-template-columns: repeat(1, 1fr);
    }
    &[data-col="2"] {
      grid-template-columns: repeat(2, 1fr);
    }
    &[data-col="3"] {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__row-boxes {
    display: grid;
    overflow: hidden;
    z-index: 0;
  }

  &__slider {
    display: grid;
    transition: all 0.2s;
  }

  &__element {
    padding: rem(20);
    background: $white;
    &.center {
      justify-content: center;
    }
  }

  &__big,
  &__cta {
    grid-column: 1 / -1;
    &__value {
      font-weight: 800;
    }
  }

  &__label {
    display: block;
    font-size: rem(10);
    text-transform: uppercase;
    border-bottom: 1px solid $primary;
    padding-bottom: rem(5);
    margin: rem(5);
    margin-bottom: rem(15);
  }

  &__value {
    display: flex;
    align-items: center;
    font-size: 1rem;
    &.bold {
      font-weight: bold;
    }
    button,
    a {
      background: none;
      color: $primary;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__download {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include media-breakpoint-up(lg) {
    &__arrow-left {
      display: block;
      position: absolute;
      left: 5px;
      top: 52%;
      svg {
        width: 15px;
        height: 25px;
      }
      &:hover {
        cursor: pointer;
      }
    }

    &__arrow-right {
      display: block;
      position: absolute;
      right: 5px;
      top: 52%;
      svg {
        width: 15px;
        height: 25px;
      }
      &:hover {
        cursor: pointer;
      }
    }

    &__arrow--disabled {
      color: getColor("smoke");
      &:hover {
        cursor: auto;
      }
    }

    &__header {
      margin-top: rem(20);
      margin-bottom: rem(15);
      display: grid;
    }

    &__super-label {
      display: flex;
      align-items: flex-end;
      font-size: rem(10);
      text-transform: uppercase;
      border-bottom: 1px solid $primary;
      padding-bottom: rem(5);
      margin: 0 rem(10);
      &-big {
        grid-column: 1 / -1;
      }
    }

    &__block {
      display: grid;
    }

    &__label {
      display: none;
    }

    &__row-boxes {
      width: calc(9 * 95px);
    }

    &__row:first-child {
      .admin-table__element:first-child {
        border-left: 1px solid $medium;
      }
    }

    &__element {
      border-top: 1px solid $medium;
      border-bottom: 1px solid $medium;
      border-right: 1px solid $medium;
    }

    &__cta {
      padding: rem(10);
      .admin-table__value.text {
        display: none;
      }
      .admin-table__value.icon {
        display: block;
      }
    }
  }

  @include media-breakpoint-down(md) {
    &__title {
      flex-direction: column;
      align-items: flex-start;
    }

    &__filter {
      width: 100%;
      margin-top: rem(20);
    }

    &__header {
      display: none;
    }

    &__row-boxes {
      .admin-table__label {
        border: 0;
      }
      .admin-table__element {
        border-bottom: 1px solid $medium;
      }
    }

    &__slider {
      grid-template-columns: repeat(3, 1fr) !important;
    }

    &__cta {
      display: flex;
      justify-content: center;
      .admin-table__value.icon {
        display: none;
      }
      .admin-table__value.text {
        display: block;
      }
    }

    &__small {
      border-right: 1px solid $medium;
    }

    &__row {
      border-top: 1px solid $medium;
      border-left: 1px solid $medium;
      border-right: 1px solid $medium;
      .admin-table__small:last-child {
        border-right: 0;
      }
    }

    &__row:last-child {
      border-bottom: 1px solid $medium;
    }

    &__row-boxes {
      border-top: 1px solid $medium;
      border-left: 1px solid $medium;
    }
  }
}
