$input-error-color: $danger;
$input-dark-gray: $dark;
$form-input-margin-v: 0;
$form-input-margin-h: 0;
$label-text-color: $dark;

.form-section {
  display: flex;

  .form {
    &-group {
      display: flex;

      > * {
        flex-grow: 1;
      }

      .form-input {
        margin: rem($form-input-margin-v) rem($form-input-margin-v);

        &:first-child {
          flex-grow: 0;
          flex-shrink: 0;
          margin-left: rem($form-input-margin-h);
        }

        &:last-child {
          margin-right: rem($form-input-margin-h);
        }
      }
    }

    &-input {
      position: relative;
      display: flex;
      align-items: center;
      padding: rem(20) 0;

      &.p-0 {
        padding: 0;
      }

      &.my-0 {
        margin-top: 0;
        margin-bottom: 0;
      }

      &.h-auto {
        min-height: rem(70);
        height: auto;

        .form-input__wrap {
          min-height: rem(40);
          height: auto;
        }
      }

      &__wrap {
        display: flex;
        align-items: center;
        width: 100%;
        // margin: rem(10) 0;
      }

      &__wrap.clear-after {
        &:after {
          content: none !important;
        }
      }

      &__wrap.has-error {
        &:after {
          content: "";
          width: 20px;
          height: 20px;
          fill: $danger;
          // background: url("/images/error_filled_rounded.svg");
          right: 10px;
          position: absolute;
          background-size: contain;
        }

        .form-input__field {
          color: $input-error-color;
          border-color: $input-error-color;
          padding-right: rem(35);

          &:focus {
            border: 2px solid;
          }

          & ~ .form-input__border {
            &::after {
              transform: scaleX(1);
            }
          }
        }
      }

      &__field {
        width: 100%;
        // height: rem(50);
        padding: rem(12) rem(15);
        border: 1px solid $medium;
        background-color: white;
        border-radius: 7px;

        font-size: rem(16);
        color: black;
        transition: all ease 0.2s;

        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }

        &:valid {
          background-color: white;
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 5px $dark;
        }

        &-action {
          position: absolute;
          right: 10px;

          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;

          &:hover {
            cursor: pointer;
          }

          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      &__field[disabled] {
        background-color: $light;
      }

      textarea.form-input__field {
        width: 100%;
        resize: none;

        &:focus {
          outline: none;
        }

        &::placeholder {
          opacity: 1;
          color: black;
        }
      }

      &__checkbox {
        position: absolute;
        opacity: 0;

        &:checked ~ .form-input__checkbox-label {
          span {
            &:after {
              background: url("/images/check-icon.svg");
              background-repeat: no-repeat;
              transform: scale(0.8);
            }
          }
        }
      }

      &__checkbox.has-error {
        & ~ .form-input__checkbox-label {
          color: $input-error-color;

          a,
          a:focus,
          a:visited {
            color: $input-error-color;
          }

          span {
            border-color: $input-error-color;

            &:after {
              background-color: $input-error-color;
            }
          }
        }
      }

      &__checkbox-label {
        position: relative;
        padding-left: rem(30);

        font-size: rem(12);
        color: $dark;
        transition: all ease 0.2s;

        a,
        a:focus,
        a:visited {
          text-decoration: underline;
          color: $primary;
          transition: all ease 0.2s;
        }

        span {
          position: absolute;
          left: 0;
          top: 50%;
          width: 20px;
          height: 20px;
          z-index: 1;
          background-color: white;
          border: 1px solid $medium;
          border-radius: 5px;
          transform: translateY(-50%);
          transition: all ease 0.2s;

          &:after {
            content: "";
            position: absolute;
            left: -1px;
            top: 50%;
            width: 25px;
            height: 20px;
            box-sizing: border-box;
            border-radius: 5px;
            margin-top: calc(-50% - 1px);
            z-index: 2;
            transform: scale(0);
            transition: all ease 0.2s;
          }
        }
      }

      &__select {
        select {
          padding-right: rem(25);
          z-index: 2;
        }

        &::after {
          content: "⋁";
          position: absolute;
          top: 50%;
          right: 0;
          z-index: 1;

          color: $input-dark-gray;
          line-height: 12px;
          transform: translate(-5px, -40%) scale(1.4, 0.7);
        }
      }

      &__label,
      &__link {
        position: absolute;
        top: 0;
        margin-bottom: 0;
      }

      &__label {
        left: 0;
        // font-weight: bold;
        color: $label-text-color;
        font-size: rem(14);
        // text-transform: uppercase;
        transition: all ease 0.3s;
      }

      &__link {
        right: 0;
        font-size: rem(11);
        color: #a0a0a0;
      }

      &__error {
        position: absolute;
        left: 0;
        bottom: 10px;
        width: 100%;
        margin: 0;
        padding: 0;
        color: $input-error-color;
        font-weight: bold;
        font-size: rem(12);
        opacity: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.active {
          -webkit-animation: fade-in-fwd 0.3s
            cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: fade-in-fwd 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        }
      }
    }
  }
}

@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@include media-breakpoint-up("lg") {
  .form-section {
    .form {
      &-group {
        .form-input {
          margin: rem($form-input-margin-v * 2) rem($form-input-margin-v * 2);

          &:first-child {
            margin-left: rem($form-input-margin-h * 2);
          }

          &:last-child {
            margin-right: rem($form-input-margin-h * 2);
          }
        }
      }

      &-input {
        &__field {
          font-size: rem(16);
        }

        &__select {
          select {
            padding-right: rem(30);
          }
        }

        &__label {
          font-size: rem(12);
        }

        &__error {
          font-size: rem(16);
        }

        &__checkbox-label {
          font-size: rem(13);
        }
      }
    }
  }
}
