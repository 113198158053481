.c-info-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgba(#000, 0.4);
  z-index: $z-i-modal;
  &.show {
    display: flex;
  }
  &__wrapper {
    position: relative;
    width: 65vw;
    display: flex;
    flex-direction: column;
    border-radius: rem(7);
    background-color: getColor(secondary);
    overflow: hidden;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: rem(15);
    color: getColor(smokegray);
    &:hover {
      cursor: pointer;
    }
  }
  &__header {
    $headerpad: 50;
    padding: rem(2*$headerpad) rem($headerpad) 0;
    &__employesbar {
      position: absolute;
      background-color: getColor('lightgray');
      top:0;
      left: 0;
      padding: 1em rem($headerpad);
      display: flex;
      justify-content: flex-end;
      width: 100%;
      .btn-link {
        background: transparent;
      }
    }
  }
  &__pretitle {
    color: getColor(tertiary);
    font-size: rem(14);
    text-transform: uppercase;
  }
  &__title {
    color: getColor(primary);
    font-size: rem(30);
    margin-top: rem(5);
    margin-bottom: rem(20);
  }
  &__info {
    color: getColor(text-gray);
  }
  &__steps {
    display: flex;
    padding: rem(50);
  }
  &__step {
    display: none;
    flex-direction: column;
    &.show {
      display: flex;
    }
    &:first-child {
      margin-right: rem(25);
    }
    &__label {
      font-weight: $font-weight-bold;
      margin-bottom: rem(10);
      color: getColor(text-gray);
    }
    &__select {
      position: relative;
      &__icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-15px, -50%);
      }
      .form-input__field {
        width: rem(260);
        padding-right: rem(40);
        font-size: rem(14);
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        outline: none;
      }
    }
  }
  &__cta-container {
    padding: rem(25) rem(50);
    background-color: getColor(primary);
    overflow: hidden;
  }
  &__forgot-password {
    display: none;
    padding: 0 rem(50) rem(20);
    font-size: rem(14);
    color: getColor(text-gray);
    &.show {
      display: block;
    }
  }
  &__cta {
    display: none;
    align-items: center;
    justify-content: space-between;
    &.show {
      display: flex;
    }
    &__text {
      color: getColor(secondary);
    }
    .cta-primary {
      margin-left: rem(10);
      flex-shrink: 0;
      border: 1px solid getColor(secondary);
    }
    &.login {
      .form-section {
        width: 100%;
        justify-content: space-between;
        .form-group {
          flex: 0 0 50%;
          margin-bottom: 0;
          padding-right: rem(20);
        }
        .cta-primary {
          margin-left: 0;
        }
      }
    }
  }
  &__form__container {
    width: 100%;
    display: flex;
  }
}

@include media-breakpoint-down(md) {
  .c-info-modal {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    &__wrapper {
      width: 100%;
      height: 100%;
      justify-content: space-between;
      border-radius: 0;
      overflow-y: scroll;
    }
    &__header {
      $headerpad: 25;
      padding: rem(3*$headerpad) rem($headerpad) 0;
      &__employesbar {
        justify-content: flex-start;
        padding: 1em rem($headerpad);
        font-size: 1rem;
      }
    }
    &__steps {
      padding: rem(50) rem(25);
      flex-direction: column;
    }
    &__step {
      margin-bottom: rem(25);
      &:first-child {
        margin-right: 0;
      }
      &__select {
        // prevent automatic zoom on focus
        select,
        select:focus {
          font-size: rem(16) !important;
        }
        .form-input__field {
          width: 100%;
        }
      }
    }
    &__forgot-password {
      padding: 0 rem(25) rem(20);
    }
    &__cta-container:not(.login) {
      padding: rem(25);
    }
    &__cta {
      flex-direction: column;
      &__text {
        margin-bottom: rem(25);
      }
      .cta-primary {
        width: 100%;
        margin-left: 0;
      }
      &.login {
        .form-section {
          flex-direction: column;
          .form-group {
            padding: 0;
            margin-bottom: rem(20);
          }
        }
      }
    }
    &__form__container {
      flex-direction: column;
    }
  }
}
