.c-progressbar {
  border-radius: rem(25);
  margin-top: rem(15);
  background: $light-grey;

  &__progress {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;
    padding: rem(10) rem(10);

    &__bar {
      flex: 1;
      border-radius: rem(13);
      background: $secondary;
      color: $light;
    }

    &__number {
      font-size: rem(30);
      color: $secondary;
      padding-left: rem(15);
    }
  }

  &--fancy {
      background-color: transparent;
    .c-progressbar__progress {
        padding: rem(10) 0;
      &__bar {
        background-image: linear-gradient(to right, $progressbar-fancy-start, $progressbar-fancy-end);
      }

      &__number {
        color: $progressbar-fancy-end;
      }
    }
  }
    &.empty{
        .c-progressbar__progress{
            padding: rem(5);
        }
    }
}
