.c-login-video {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $black-trans;
  &.show {
    display: block;
  }
  .login-video {
    &__wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }
    &__video {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      z-index: 1;
    }
    &__closebtn {
      position: absolute;
      top: rem(25);
      right: rem(25);
      color: $light;
      cursor: pointer;
      z-index: 10;
    }
    &__courses {
      position: absolute;
      z-index: 10;
      border-top-right-radius: rem(10);
      border-top-left-radius: rem(10);
      background: $light-trans;
      cursor: pointer;
      &__text {
        color: $primary;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .c-login-video {
    .login-video {
      &__courses {
        $h: 66px;
        $w: 390px;
        height: $h;
        width: $w;
        top: 50%;
        left: $h;
        transform-origin: 0 0;
        transform: translateY(calc(-1 * (50% + #{$w} / 2))) rotate(90deg);
        padding: rem(25) rem(140);
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .c-login-video {
    .login-video {
      &__courses {
        $h: 66px;
        $w: 320px;
        height: $h;
        width: $w;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
