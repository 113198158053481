.l-relators {
  .c-area-info {
    min-height: auto;
    margin-bottom: 0;
    .area-info__content {
      padding-bottom: 0;
    }
  }
  .relators {
    &__title {
      margin: rem(40) 0 rem(50);
      text-align: center;
      font-weight: 600;
      color: getColor("primary");
      background-color: getColor("lightgray");
      padding: rem(25);
      border-radius: rem(50);
    }
    &__wrapper {
      margin-top: rem(20);
      display: grid;
      grid-template-columns: 49% 49%;
      column-gap: 2%;
    }
    &__area {
      display: block;
      &__card__wrapper {
        margin: rem(10) 0 rem(50);
      }
      &__incard {
        display: none;
        margin-bottom: rem(10);
      }
      &__title {
        margin-bottom: rem(5);
        font-size: rem(22);
        color: getColor("primary");
      }
      &__subtitle {
        font-size: rem(14);
        color: getColor("smokegray");
        font-style: italic;
      }
    }
    &__card__wrapper {
      height: 100%;
      background-color: getColor("lightgray");
    }
  }
}

@include media-breakpoint-down("md") {
  .l-relators {
    .project-description {
      display: block;
      &__logo {
        display: none;
      }
      &__content {
        margin-left: 0;
      }
    }
    .relators {
      &__wrapper {
        display: block;
      }
      &__area {
        display: none;
        &__incard {
          display: block;
        }
      }
    }
  }
}
