.c-podcast-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#000, 0.4);
  z-index: $z-i-modal;
  display: none;
  justify-content: center;
  align-items: center;
  &.active {
    display: flex;
  }
  .podcast {
    &__wrapper {
      width: 60%;
      padding: rem(15);
      border-radius: rem(10);
      background-color: getColor('light');
    }
    &__close {
      text-align: right;
      color: getColor(smokegray);
      &:hover {
        cursor: pointer;
      }
      &__icon {
        margin-left: rem(5);
      }
    }
    &__content {
      height: 100%;
      padding: rem(25) rem(30);
      display: flex;
      flex-direction: column;
    }
    &__image {
      display: block;
      position: relative;
      height: rem(200);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: rem(10);
      }
      &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.25);
        border-radius: rem(10);
        z-index: $z-i-main;
      }
      &-fallback {
        position: relative;
        height: rem(200);
        border-radius: rem(10);
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        background: linear-gradient(90deg, #ee8aa6, #c673f0);
        i {
          display: block;
          width: 25%;
          color: rgba(255,255,255,.4);
        }
      }
    }
    &__title {
      position: absolute;
      width: calc(100% - 80px);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: rem(20);
      color: getColor('light');
      z-index: $z-i-mainup;
    }
    &__badge {
      position: absolute;
      top: rem(10);
      left: rem(10);
      background-color: $light;
      border-radius: 1rem;
      padding: 0.3rem 1rem;
      color: $black;
      &__completed {
        font-weight: bold;
      }
    }
    &__player {
      margin: rem(20) 0;
      audio {
        display: none;
        pointer-events: none;
      }
      &__custom {
        display: flex;
        align-items: center;
        position: relative;
        &.playing {
          .podcast__player__play {
            display: none;
          }
          .podcast__player__pause {
            display: flex;
          }
        }
      }
      &__button {
        flex: 0 0 rem(80);
        height: rem(80);
        border-radius: 50%;
        background-color: getColor('primary');
        z-index: 1;
        &:hover {
          cursor: pointer;
        }
      }
      &__play,
      &__pause {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: rem(30);
        }
      }
      &__play {
        svg {
          margin-left: rem(5);
          path {
            fill: #fff;
          }
        }
      }
      &__pause {
        display: none;
      }
      &__time {
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: rem(14);
      }
      &__bar {
        flex: 0 0 calc(100% - #{rem(70)});
        height: rem(45);
        transform: translateX(-10px);
        background-color: getColor('lightsmoke');
        z-index: 0;
        display: flex;
        align-items: center;
        &__total {
          position: relative;
          width: 100%;
          height: rem(8);
          margin-left: rem(10);
          margin-right: rem(15);
          border-top-right-radius: rem(5);
          border-bottom-right-radius: rem(5);
          background-color: getColor('medium');
        }
        &__progress {
          display: block;
          width: 0;
          height: 100%;
          border-top-right-radius: rem(5);
          border-bottom-right-radius: rem(5);
          background-color: getColor('primary');
          &:hover {
            cursor: pointer;
          }
        }
        &__chapter {
          position: absolute;
          display: block;
          width: 2px;
          height: rem(45);
          top: 50%;
          transform: translate(-50%, -50%);
          background-color: rgba(getColor('primary'), 0.6);
          &:hover {
            cursor: pointer;
            .chapter-info {
              display: block;
            }
          }
          .chapter-info {
            display: none;
            position: absolute;
            width: rem(85);
            padding: rem(10);
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            border-radius: rem(5);
            background-color: getColor('bluegray');
            text-align: center;
            font-size: rem(14);
            &:before {
              content: '';
              display: block;
              position: absolute;
              top: calc(100% - 1px);
              left: 50%;
              transform: translateX(-50%);
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-top: 7px solid getColor('bluegray');
            }
            &__title {
              margin-bottom: rem(5);
            }
          }
        }
      }
    }
    &__authors {
      &__names {
        font-weight: $font-weight-bold;
      }
    }
    &__chapters {
      margin: rem(20) 0;
    }
    &__chapter {
      margin: rem(5) 0;
      padding-left: rem(20);
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 12px solid transparent;
      }
      &.current {
        &:before {
          border-left-color: getColor('primary');
        }
        .podcast__chapter__title {
          font-weight: $font-weight-bold;
        }
      }
      &.completed {
        color: getColor('medium');
      }
    }
  }
}

@include media-breakpoint-down('md') {
  .c-podcast-modal {
    .podcast {
      &__wrapper {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }
      &__close {
        &__text {
          display: none;
        }
      }
      &__content {
        padding: rem(25) 0;
      }
      &__player {
        margin: rem(20) 0 rem(25);
        &__button {
          flex: 0 0 rem(60);
          height: rem(60);
        }
        &__play,
        &__pause {
          svg {
            width: rem(20);
          }
        }
        &__time {
          bottom: -5px;
        }
        &__bar {
          flex: 0 0 calc(100% - #{rem(50)});
          height: rem(30);
          &__total {
            margin-right: rem(10);
          }
          &__chapter {
            height: rem(30);
          }
        }
      }
      &__chapter {
        margin: rem(10) 0;
      }
      &__credits {
        margin: auto 0 rem(5);
      }
    }
  }
}
