input,textarea{
  appearance: none;
}

.input-select{
  border: solid 1px getColor('lightgray');
  background: getColor('light');
  color: getColor('text-dark');
  border-radius: rem(10);
  padding: rem(15) rem(5);
}
.input-textarea{
  width: 100%;
  min-height: rem(120);
  border: solid 1px getColor('lightgray');
  padding: rem(15) rem(5);
  border-radius: rem(10);
}
.form-field{
  margin-bottom: rem(20);
  label{
    margin-bottom: rem(14);
    font-size: rem(16);
  }
  input,select,textarea{
    width: 100%;
  }
}
