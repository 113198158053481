.forum-link {
  @include mainContainer();
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  &__content {
    flex: 1;

    max-width: rem(650);
    padding: rem(20) rem(30);
    border-radius: rem(30);

    background-image: url('/images/bg-forum.png');
    background-position: center;
    background-size: cover;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  &__text,
  &__cta {
    color: $light;
    font-weight: bold;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &__cta {
    text-decoration: underline;

    &:hover {
      color: $light;
    }
  }
}
