.c-area-info {
  position: relative;
  min-height: rem(200);
  display: flex;
  justify-content: center;
  margin-bottom: rem(15);
  .area-info {
    &__content {
      display: flex;
      flex-flow: column;
      align-items: center;
      position: relative;
      padding: rem(35) 0;
      width: min(80%, 800px);
    }
    &__bg-img {
      display: block;
      @include mask();
      img {
        @include coverImg();
      }
      &:after {
        content: "";
        @include mask();
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
    &__section {
      color: $secondary;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: rem(15);
    }
    &__title {
      color: $primary;
      font-size: rem(44);
      font-weight: lighter;
      margin-bottom: rem(30);
      &::first-letter {
        text-transform: capitalize;
      }
    }
    &__description {
      text-align: center;
      font-weight: lighter;
      strong {
        font-weight: 700;
      }
    }
    &__back-link {
      position: absolute;
      bottom: rem(20);
      left: rem(20);
      font-size: rem(18);
      a {
        color: getColor("primary");
      }
    }
  }

  &--fancy {
    @extend .c-area-info;
    border-bottom-left-radius: rem(15);
    border-bottom-right-radius: rem(15);

    overflow: hidden;
    background-position: center;
    background-size: cover;

    .area-info {
      &__section,
      &__title {
        color: $light;
      }
      &__description {
        color: $light;
        font-weight: 400;
      }
      &__back-link {
        a {
          color: getColor("light");
        }
      }
    }
  }
}
