.login-container {
  .login {
    &__logos {
      position: fixed;
      width: 100%;
      padding: rem(15) rem(30);
      display: none;
      justify-content: space-around;
      align-items: center;
      z-index: $z-i-main;
    }
    &__desktop {
      display: block;
    }
  }
}

@include media-breakpoint-down(md) {
  .page-login {
    overflow-y: hidden;
    header,
    footer {
      display: none;
    }
    .login-container {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      .login {
        &__logos {
          display: flex;
        }
        &__desktop {
          display: none;
        }
      }
    }
  }
}
