.footer {
  &__partners {
    background: #ffffff;
    padding-top: rem(50);

    &__text {
      text-align: center;
      margin: 0 rem(20);
    }

    &__logos {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      & img {
        max-width: 150px;
        max-height: 100px;
        padding: rem(20);
      }
    }
  }

  hr {
    margin: 0;
  }

  &__link {
    color: $black;

    &-list {
      list-style-type: none;
      padding: 0;
    }

    &-item {
      padding: 0.5rem 0;
      margin-bottom: 1rem;
      display: inline-block;

      &:not(:last-child) {
        &::after {
          content: " \2022";
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &__partners {
      &__logos {
        & img {
          max-width: 120px;
          padding: rem(10);
        }
      }
    }
  }
}
