$top-offset: 75px;

.page-privacy {
  .hero-general-container {
    padding-top: rem(50);
    margin: 0 auto;
  }

  .hero-description {
    color: $primary;
  }
}

.privacy {
  &-container {
    @extend .container;

    margin: 0 auto;
    padding-bottom: rem(60);

    *[id] {
      &::before {
        content: '';
        display: block;
        height: $top-offset;
        margin-top: -$top-offset;
        visibility: hidden;
        position: relative;
        z-index: -1;
      }
    }

    p,
    li {
      color: black;
      font-size: rem(14);
      line-height: 1.3;
    }

    h3,
    h4 {
      font-weight: bold;
      color: $primary;
    }

    h3 ~ p,
    h4 ~ p {
      margin-bottom: rem(15);
    }

    h3 {
      font-size: rem(18);
    }

    h4 {
      position: relative;
      font-size: rem(16);
      margin-top: rem(10);
      margin-bottom: rem(15);
      padding-left: rem(30);

      &::after {
        position: absolute;
        top: $top-offset;
        left: 0;
        content: "";
        width: 25px;
        height: 18px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('/images/right_arrow_blue.svg');
      }
    }

    h4 ~ p {
      padding-left: rem(30);
    }

    h4 ~ ul {
      margin-left: rem(30);
    }

    ul {
      margin-bottom: rem(15);

      li {
        padding-left: rem(5);
        margin-bottom: rem(10);
      }
    }
  }

  &-content {
    width: 100%;
    padding: rem(15);
    background-color: #E9F1EF;

    ul {
      counter-reset: item;

      li {
        display: block;
        position: relative;

        &:before {
          content: counters(item, '.') '.';
          counter-increment: item;
          position: absolute;
          top: 0;
          right: 100%;
          margin-top: 1px;
          white-space: pre;
          font-family: 'Courier New', monospace;
        }
      }
    }

    ul {
      list-style: decimal;
      margin-left: rem(15);
      margin-bottom: rem(45);

      &:last-child {
        margin-bottom: 0;
      }

      ul {
        margin-left: rem(0);
        padding-left: rem(5);
      }

      li {
        font-size: rem(14);
        padding-left: 10px;
        margin-bottom: rem(10);

        &:first-child {
          margin-top: rem(10);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        color: black;
        text-decoration: underline;
      }
    }

    p {
      margin-top: rem(10);
      margin-bottom: rem(20);
    }
  }

  &-row {
    @extend .row;

    > * {
      margin-bottom: rem(40);
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &-col-12 {
    @extend .col-12;
  }
}

@include media-breakpoint-up('lg') {
  .page-privacy {
    .hero-general-container {
      margin-bottom: -250px;
    }

    .hero-title {
      margin-bottom: rem(55);
    }

    .hero-general-container {
      max-width: 800px;
      margin-top: rem(10);
      padding-top: rem(103);
      padding-bottom: 285px;
    }
  }

  .privacy {
    &-container {
      max-width: 800px;
      padding-bottom: rem(155);

      p,
      li {
        font-size: rem(16);
        line-height: 1.5;
      }

      h3 {
        font-size: rem(20);
        font-weight: bold;
      }

      h4 {
        font-size: rem(18);
        margin-top: rem(40);
        margin-bottom: rem(18);
        padding-left: rem(40);
      }

      h4 ~ p {
        padding-left: rem(40);
      }

      h4 ~ ul {
        margin-left: rem(40);
      }

      ul {
        margin-bottom: rem(30);

        li {
          padding-left: rem(20);
        }
      }
    }

    &-content {
      padding: rem(80) rem(65);

      ul {
        margin-left: rem(0);
        padding-left: rem(38);

        ul {
          margin-left: rem(20);
        }

        li {
          font-size: rem(16);
          margin-bottom: rem(20);

          &:first-child {
            margin-top: rem(20);
          }
        }
      }

      p {
        margin-top: 0;
      }
    }

    &-row {
      @extend .row;

      > * {
        margin-bottom: rem(65);
      }
    }
  }
}
