.c-cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: $z-i-modal;
  background: getColor("secondary");
  border-top: 1px solid getColor("primary");
  .cookie-bar {
    &__wrapper {
      @include mainContainer();
      display: grid;
      grid-template-columns: 2fr auto;
      align-items: center;
      justify-content: space-around;
      padding: rem(20) 0;
      gap: rem(20);
    }
    &__content {
      font-size: rem(16);
      line-height: 1.4;
    }
    &__ctas {
    }
  }
}

@include media-breakpoint-down("md") {
  .c-cookie-bar {
    .cookie-bar {
      &__wrapper {
        grid-template-columns: 1fr;
      }
    }
  }
}
