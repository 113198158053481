.c-login-skills-bar {
  background-color: getColor(secondary);
  overflow: hidden;
  .skills-bar {
    &__wrapper {
      padding: rem(50) 0 rem(20);
    }
    &__title {
      padding-left: calc(#{var(--main-container-pad-x)} * 1px);
      font-size: rem(18);
      color: getColor(tertiary);
      text-transform: uppercase;
      margin-bottom: rem(15);
    }
    &__list {
      padding-left: calc(#{var(--main-container-pad-x)} * 1px);
      display: flex;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      transition: all 0.2s;
      user-select: none;
      touch-action: none;
      cursor: grab;
      &.active {
        transform: scale(1.025);
        cursor: grabbing;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      &__item {
        width: rem(180);
        flex-shrink: 0;
        margin-right: rem(10);
        padding: rem(8) 0;
        text-align: center;
        font-size: rem(14);
        color: getColor(primary);
        border: 1px solid getColor(primary);
        border-radius: rem(50);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
