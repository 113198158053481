.c-post {
  position: relative;
  border-radius: rem(10);
  background: $light-grey;

  padding: rem(30);
  margin-bottom: rem(10);

  &__flag-cta {
    position: absolute;
    top: 0;
    right: 0;
    padding: rem(16);
    color: $dark;
  }

  &__user {
    color: $dark;
  }

  &__quote {
    background: $light;
    border-left: solid 2px $secondary;
    padding: rem(20);
    margin-top: rem(20);
  }

  &__text {
    color: getColor('text-dark');
    margin-top: rem(20);
    margin-bottom: rem(30);
  }

  &__cta-row {
    display: flex;
    flex-flow: row nowrap;
  }

  &__view-replies-cta {
    display: block;
    color: $dark;
    margin-right: rem(25);
  }

  &__reply-cta {
    display: block;
    color: $primary;
    font-weight: bold;
  }

  &--reply {
    background: transparent;
    border-bottom: solid 1px $dark;
    border-radius: unset;
    padding: rem(30) 0;
    .c-post__cta-row {
      justify-content: flex-end;
    }
  }

  //after flagged
  .flagged{
    .c-post__flag-cta{
      display:none;
    }
  }
}
