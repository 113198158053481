.c-login-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  justify-content: center;
  align-items: center;
  background-color: rgba(#000, 0.4);
  z-index: $z-i-modal;
  &.show {
    display: flex;
  }
  &__wrapper {
    position: relative;
    width: 60vw;
    display: flex;
    flex-direction: column;
    border-radius: rem(7);
    background-color: getColor(secondary);
    overflow: hidden;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: rem(15);
    color: getColor(smokegray);
    &:hover {
      cursor: pointer;
    }
  }
  &__header {
    padding: rem(50) rem(50) 0;
  }
  &__pretitle {
    color: getColor(tertiary);
    font-size: rem(14);
    text-transform: uppercase;
  }
  &__title {
    color: getColor(primary);
    font-size: rem(30);
    margin-top: rem(5);
  }
  &__content {
    display: flex;
    align-items: flex-end;
  }
  &__form {
    flex: 0 0 50%;
    .form-section {
      display: block;
    }
    .form-group {
      display: block;
    }
    &__container {
      margin: rem(25) 0;
      padding: 0 rem(50);
      border-right: 1px solid getColor(lightsmoke);
    }
    &__title {
      font-weight: $font-weight-bold;
      color: getColor(text-gray);
    }
    &__forgot-password {
      font-size: rem(12);
      color: getColor(text-gray);
    }
    &__error {
      color: getColor(primary);
      margin-top: rem(5);
      font-size: rem(14);
    }
  }
  &__info {
    flex: 0 0 50%;
    &__container {
      padding: rem(50);
    }
    &__text {
      margin-bottom: rem(10);
      color: getColor(text-gray);
      &:first-child {
        font-weight: $font-weight-bold;
      }
    }
  }
  &__cta {
    display: flex;
    justify-content: flex-end;
    padding: rem(20);
    background-color: getColor(primary);
    .cta-primary {
      display: none;
    }
    .cta-secondary {
      display: flex;
    }
  }
}

@include media-breakpoint-down(md) {
  .c-login-modal {
    &__wrapper {
      width: 100%;
      height: 100%;
      border-radius: 0;
      overflow-y: scroll;
    }
    &__header {
      padding: rem(50) rem(25) 0;
    }
    &__content {
      flex-direction: column;
      padding: rem(25);
    }
    &__form {
      width: 100%;
      border-bottom: 1px solid getColor(lightsmoke);
      &__container {
        margin: 0;
        padding: 0;
        border-right: 0;
      }
    }
    &__info {
      width: 100%;
      margin-top: rem(25);
      &__container {
        margin: 0;
        padding: 0;
      }
    }
    &__cta {
      display: block;
      padding: rem(25) 0;
      background-color: getColor(secondary);
      .cta-primary {
        display: flex;
        width: 100%;
      }
      .cta-secondary {
        display: none;
      }
    }
  }
}
