.home {
  //&-infobar {
  //  display: flex;
  //  flex-flow: row wrap;
  //  justify-content: space-between;
  //  margin-top: rem(60);
  //
  //  &__filters {
  //    &__to-complete {
  //      &::first-letter {
  //        text-transform: capitalize;
  //      }
  //    }
  //    &__to-completed {
  //      &::first-letter {
  //        text-transform: capitalize;
  //      }
  //    }
  //  }
  //
  //  &__continue {
  //    &__text {
  //      &::first-letter {
  //        text-transform: capitalize;
  //      }
  //    }
  //
  //    &__module {
  //      &__text {
  //        display: inline-block;
  //        font-size: rem(20);
  //
  //        &::first-letter {
  //          text-transform: capitalize;
  //        }
  //      }
  //
  //      &__cta {
  //        color: $primary;
  //        text-decoration: none;
  //
  //        &::first-letter {
  //          text-transform: capitalize;
  //        }
  //
  //        &:hover {
  //          text-decoration: none;
  //        }
  //      }
  //    }
  //  }
  //}

  &-course {
    &__tab {
      color: $primary;
      background-color: transparent;
      border: thin solid #232E7E;
      border-radius: 2rem;
      font-weight: 700;
      padding-left: 2rem;
      padding-right: 2rem;
      margin-right: 2rem;
      margin-bottom: 1rem;

      &.active {
        color: $white;
        background-color: $primary;
      }

      &-descriptor {
        color: $primary;
        font-size: 26px;
        font-weight: 600;
        margin: 1rem 2rem 1rem 0;
      }

      &--disabled {
        border-color: #949494;
        color: #949494;

        &:hover {
          color: #949494;
        }
      }
    }

      &__grid-items{
          display: grid;
          grid-template-columns: repeat(3,1fr);
          gap: rem(35) rem(22);
          @include media-breakpoint-down('lg'){
              grid-template-columns: repeat(2,1fr);
          }
          @include media-breakpoint-down('md'){
              grid-template-columns: 1fr;
          }
      }
    &__item-column {
      display: flex;
      flex-direction: column;
    }

    &__item-column:last-child &__separator {
      display: none;
    }

    &__item-column:nth-last-child(2) &__separator {
      &--odd {
        @include media-breakpoint-up('md') {
          display: none;
        }
      }
    }


    &__separator {
      border-color: rgba(148, 148, 148, 0.5);
      border-bottom: 0;
      margin: 0;
    }
  }
}

//.course {
//  &-card {
//    display: flex;
//
//    &__media {
//      margin-right: 1rem;
//      flex: 0 0 33.3%;
//
//      @include media-breakpoint-up('md') {
//        margin-right: 2rem;
//      }
//    }
//
//    &__image {
//      @extend .img-fluid;
//      width: 100%;
//    }
//
//    &__content {
//      flex: 1 1 auto;
//    }
//
//    &__status {
//      display: inline-block;
//      color: $primary;
//      background-color: transparent;
//      padding: 0.25rem 1rem 0.125rem;
//      border-width: thin;
//      border-style: solid;
//      border-color: $primary;
//      font-size: 9px;
//      text-transform: uppercase;
//
//      .course-card--active & {
//        border-color: $secondary;
//        background-color: $secondary;
//      }
//
//      .course-card--complete & {
//        background-color: $primary;
//      }
//
//      .course-card--active &,
//      .course-card--complete & {
//        color: $white;
//        font-weight: 700;
//      }
//    }
//
//    &__link {
//      display: inline-block;
//      padding: 0.5rem 0;
//      margin: 0.5rem 0;
//      font-size: 22px;
//      color: $primary;
//
//      .course-card--active & {
//        color: $secondary;
//      }
//
//      .course-card--active &,
//      .course-card--complete & {
//        font-weight: 600;
//      }
//    }
//
//    &__ordinal {
//      &::after {
//        content: ' \2022';
//      }
//    }
//
//    &__footer {
//      color: #949494;
//      text-transform: uppercase;
//      margin-top: 1rem;
//    }
//  }
//}
