.c-course-card {
  --h-card-img : #{rem(315)};
  --title-flex-align: flex-end;
  --f-size-title: #{rem(34)};
  --bg-fallback: linear-gradient(90deg,#ee8aa6,#c673f0);
  display: block;
  margin: 0;
  &:hover {
    text-decoration: none;
  }
  .card{
    &__img {
      display: block;
      height: var(--h-card-img);
      border-radius: rem(15);
      position: relative;
      overflow: hidden;
      background-position: center;
      background-size: cover;
    }
    &__anchor{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: $z-i-main;
    }
    &__bg-pic{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      img{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.25);
      }
    }
    &__bg-fallback{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      background: var(--bg-fallback);
      i{
        display: block;
        width: 40%;
        color: rgba(255,255,255,.4);
      }
    }
    &__img-content{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: var(--title-flex-align);
      padding: rem(20);
    }
    &__progressbar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: rem(4);
      &__progress {
        background: $primary;
        height: 100%;
      }
    }
    &__title {
      width: 50%;
      font-size: var(--f-size-title);
      color: $light;
      &::first-letter {
        text-transform: capitalize;
      }
    }
    &__badge {
      position: absolute;
      top: rem(10);
      left: rem(10);
      background-color: $light;
      border-radius: 1rem;
      padding: 0.3rem 1rem;
      color: $black;
      &__completed {
        font-weight: bold;
      }
    }
    &__type-icon {
      position: absolute;
      right: rem(15);
      bottom: rem(15);
      color: getColor('light');
    }
    &__video-icon{
      align-self: center;
      margin: 0 auto;
      i{
        width: 50px;
      }
    }
    &__description {
      padding: rem(15) rem(5) 0 rem(5);
      color: $black;
      font-size: rem(17);
      font-weight: $font-weight-light;
      line-height: 1.2;
      &::first-letter {
        text-transform: capitalize;
      }
      &__video {
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }

  &[data-variant='course'],
  &[data-variant='exam'],
  &[data-variant='video'],
  &[data-variant='podcast'] {
    --h-card-img: #{rem(220)};
    --f-size-title: #{rem(25)};
  }
  &[data-variant='podcast'] {
    --title-flex-align: center;
    &:hover {
      cursor: pointer;
    }
  }
  &[data-variant='course']{
    --title-flex-align: center;
  }
  &[data-variant='exam']{
    --title-flex-align: flex-start;
    --bg-fallback: #{$primary};
    .card{
      &__img-content{
        padding: rem(20) rem(50);
        flex-flow: column;
        justify-content: center;
      }
      &__badge{
        display: none;
      }
      &__title{
        margin-bottom: rem(20);
      }
      &__exam {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__info {
          color: $light;
          font-size: rem(18);
        }
        &__result {
          margin-top: rem(5);
          &__percent {
            font-size: rem(25);
          }
        }
        &__cta {
          padding: rem(10) rem(60);
          font-weight: bold;
          &[disabled] {
            opacity: 1;
            background: getColor('medium');
            color: getColor('smokegray');
          }
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down('md') {
  .c-course-card {
    &[data-variant='exam'] {
      .card {
        &__img-content {
          padding: rem(20);
        }
        &__exam {
          flex-direction: column;
          &__result {
            margin-top: rem(10);
          }
          &__cta {
            width: 100%;
            margin-top: rem(10);
          }
        }
      }
    }
  }
}
