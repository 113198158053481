.form {
  &-container {
    @extend .container;
    margin: 0 auto;
  }

  &-row {
    @extend .row;
  }

  &-col-lg-6 {
    @extend .col-lg-6;
  }

  &-col-lg-5 {
    @extend .col-lg-5;
  }

  &-col-lg-4 {
    @extend .col-lg-4;
  }

  &-col-lg-2 {
    @extend .col-lg-2;
  }

  &-col-12 {
    @extend .col-12;
  }

  &-header-wrap,
  &-fields,
  &-cta {
    @extend .col-12;
  }

  &-header {
    display: block;
    justify-content: space-between;
    align-items: flex-end;
    padding: rem(60) rem(5) rem(15);

    color: $primary;
    font-size: rem(11);

    // border-bottom: 1px solid #949494;

    &__title {
      font-size: rem(22);
      font-weight: 600;
    }

    &__info {
      color: $dark;
      font-style: italic;
    }
  }

  &-fields {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: rem(30);
    padding-bottom: rem(48);

    // &__count {
    //   display: flex;
    //   flex-grow: 1;
    //   flex-shrink: 1;
    // }

    &__input-wrap {
      @extend .row;
      @extend .no-gutters;
      display: flex;
      flex-grow: 1;
      // max-width: 800px;
      width: 100%;
    }

    &:nth-child(odd) {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 100vw;
        height: 100%;
        transform: translateX(-50%);
        background-color: #dfeee9;
        z-index: -1;
      }
    }

    *[class*="col"] {
      padding-bottom: 10px;
    }
  }

  &-cta {
    display: flex;
    justify-content: flex-end;
    padding-bottom: rem(60);

    .btn {
      width: 100%;
    }
  }

  &-sep {
    margin-top: 0;
    margin-bottom: rem(16);
    border-top: 1px solid #c6c6c6;
  }
}

@include media-breakpoint-up("lg") {
  .form {
    &-header {
      display: flex;
      padding-top: rem(38);
      padding-bottom: rem(20);

      &__title {
        font-size: rem(25);
      }

      &__info {
        font-size: rem(12);
      }
    }

    &-fields {
      padding: rem(25) rem(80) rem(40);
      flex-direction: row;

      // &__count {
      //   justify-content: center;
      // }

      *[class*="col"] {
        padding: 0 rem(15);
      }
    }

    &-cta {
      padding-right: rem(92);
      padding-left: rem(92);
      padding-bottom: rem(65);

      .btn {
        max-width: 375px;
      }
    }

    &-sep {
      margin-right: rem(15);
      margin-left: rem(15);
    }
  }
}
