.c-infobar {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: rem(60);
    .infobar{
        &__filters {
            display:flex;
            align-items: center;
            &__label{
                margin-right: rem(10);
                color: $primary;
                font-size: rem(25);
                font-weight: bold;
            }
            [class^='cta-']{
                margin-right: rem(10);
            }
        }

        &__continue {
            &__text {
                &::first-letter {
                    text-transform: capitalize;
                }
            }

            &__module {
                &__text {
                    display: inline-block;
                    font-size: rem(20);

                    &::first-letter {
                        text-transform: capitalize;
                    }
                }

                &__cta {
                    color: $primary;
                    text-decoration: none;

                    &::first-letter {
                        text-transform: capitalize;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down('sm'){
  .c-infobar{
    .infobar{
      &__filters{
        flex-flow: row wrap;
        margin-bottom: rem(20);
        &__label{
          flex:  0 0 100%;
        }
        [class^='cta-']{
          flex: 0 0 49%;
          margin: rem(10) 0;
          padding: 0 rem(10);
          &:last-child {
            margin-left: auto;
          }
        }
      }
    }
  }
}
