.btn {
  font-weight: bold;
  // text-transform: uppercase;

  img {
    width: 14px;
    height: 14px;
    margin-right: rem(17);
    fill: white;
    transition: all 0.6s ease-out;
  }

  &.next {
    img {
      transform: translateX(-15px);
    }

    &:hover {
      img {
        transform: translateX(0);
      }
    }
  }

  &.previous {
    &:hover {
      img {
        transform: translateX(-15px);
      }
    }
  }
}
