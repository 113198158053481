blockquote {
  margin: 1em auto;
  border-left: 3px solid $secondary;
  background: $light-grey;
  padding: 1em;
  strong {
    color: $primary;
  }
  em {
    color: $dark;
  }
}