.page-complete-success,
.page-complete-fail {
  .hero-general-container {
    padding-top: rem(65);
    padding-bottom: 0;
    margin-bottom: 0;

    &:after {
      content: none;
    }
  }

  .hero-general__logo {
    display: none;
  }

  .hero-title {
    margin-bottom: rem(15);
    padding-top: rem(53);
    border-top: thin solid #949494;
  }

  .hero-subtitle {
    margin-bottom: rem(40);
  }

  .hero-general__image {
    align-self: center;
  }
}

.course-complete {
  &-container {
    @extend .container;
    // padding-bottom: rem(60);
  }

  &-row {
    @extend .row;

    // > * {
    //   @extend .col-12;
    //   margin-top: rem(50);
    // }

    // > *:first-child {
    //   margin-top: 0;
    // }
  }

  &-title {
    color: $primary;
    font-size: rem(16);
    line-height: 1.3;
    margin-bottom: 1.5rem;
  }

  &-text {
    font-size: rem(14);
    margin-bottom: 1.5rem;
    text-align: center;
  }

  &-image-wrap {
    display: flex;
    justify-content: center;
  }

  &-image {
    object-fit: contain;
    border-radius: 10px;
  }

  &-cta-positive {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    form {
      width: 100%;
    }

    .btn-download {
      position: relative;
      width: 80%;
      font-size: rem(16);
      font-weight: 400;
      color: $primary;
      border: 1px solid $primary;
      background-color: $white;
      padding: rem(15) rem(40);
      border-radius: 30px;
      margin-top: rem(10);
      margin-bottom: rem(40);

      svg {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translate(0, -50%);
      }

      &:hover {
        color: $white;
        background-color: $primary;

        svg {
          fill: $white;
        }
      }
    }

    .btn-primary {
      max-width: 250px;
      border-radius: 5px;
      padding: rem(12) rem(40);
    }
  }

  &-cta-negative {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .btn {
      width: 80%;
      border-radius: 5px;
      padding: rem(12);
      margin: 10px;
    }

    .btn-back {
      color: $primary;
      background-color: $white;
      border: 1px solid $primary;
      &:hover {
        color: $white;
        background-color: $primary;
      }
    }
  }
}

@include media-breakpoint-up("lg") {
  .page-complete-success,
  .page-complete-fail {
    .hero-general-container {
      padding-top: rem(110);
      padding-bottom: rem(180);
      margin: 0 auto;
      margin-bottom: rem(-50);

      &:after {
        content: "";
      }
    }

    .hero-general__logo {
      display: block;
    }

    .hero-title {
      margin-bottom: rem(20);
      padding-top: 0;
      border: 0;
    }

    .hero-subtitle {
      margin-bottom: rem(45);
    }
  }

  .course-complete {
    &-container {
      max-width: 800px;
      // padding-bottom: rem(180);
    }

    // &-row {
    //   > * {
    //     margin-top: rem(107);
    //   }
    // }

    &-title {
      font-size: rem(20);
      line-height: 1.5;
    }

    &-text {
      font-size: rem(14);
    }

    &-image {
      max-height: 430px;
    }

    &-cta-negative {
      flex-direction: row;
      .btn {
        width: 30%;
      }
    }
  }
}
