.l-credits {
  @include mainContainer();
  display: flex;
  justify-content: center;
  .credits {
    &__title {
      margin-bottom: rem(25);
    }
    &__subtitle {
      margin-left: rem(25);
      margin-bottom: rem(10);
    }
    &__list {
      margin-left: rem(25);
      margin-bottom: rem(25);
      list-style-type: lower-latin;
      li {
        margin: rem(5) 0;
        padding: 0 rem(10);
      }
    }
  }
}
