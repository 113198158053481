.c-form-comment-forum{
  &__quote {
    background: $light;
    border-left: solid 2px $secondary;
    padding: rem(20);
    margin: rem(20) 0;

    &__user {
      color: $dark;
    }

    &__text {
      margin-top: rem(20);
      margin-bottom: rem(30);
    }
  }

  .form-comment{
    &__text{

    }
    &__submit{
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
