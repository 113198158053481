.admin-user-info {
  .content {
    background-color: getColor("light");
  }

  .hero {
    &-user-container {
      padding-top: rem(25);
      padding-bottom: rem(25);
    }
    &-user-content {
      a {
        margin-bottom: rem(25);
      }
    }
    &-description {
      font-size: rem(18);
      &__row {
        margin-bottom: rem(5);
      }
      &__label {
        color: getColor('smokegray');
        padding-right: rem(5);
      }
    }
    &-user-logo {
      svg {
        display: none;
      }
    }
    &-course-desc {
      &__label {
        color: getColor('smokegray');
        text-transform: uppercase;
        margin-bottom: rem(5);
      }
      &__name {
        color: getColor('primary');
        font-weight: bold;
        font-size: rem(25);
        margin-bottom: rem(5);
      }
      &__code {
        color: getColor('primary');
        font-size: rem(20);
      }
    }
  }

  .progress-title {
    @extend .container;
    color: #a0a0a0;
    font-size: rem(25);
    font-weight: 500;
  }

  .c-progressbar {
    @extend .container;
  }

  .admin-table-user {
    background-color: $white;
    .admin-table {
      &__value.description {
        font-size: rem(14);
        font-weight: 200;
      }
    }
    a {
      color: $black;
    }
  }
}

@include media-breakpoint-up("lg") {
  .admin-user-info {
    .admin-table-user {
      .admin-table {
        &__header,
        &__block {
          grid-template-columns: 18% 30% 27% 25%;
        }
        &__element {
          display: flex;
        }
      }
    }
  }
}
