div#driver-popover-item {
  padding: rem(35) rem(25) rem(25) rem(35) !important;
  min-width: 400px !important;
  max-width: 400px !important;
  border-radius: 15px !important;
  .driver-popover-title {
    margin-bottom: rem(10) !important;
    font-weight: normal !important;
    color: getColor('primary');
  }
  .driver-popover-footer {
    margin-top: rem(15) !important;
    .driver-btn-group {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .driver-prev-btn,
    .driver-next-btn {
      @extend %cta;
      display: flex !important;
      width: rem(125);
      font-size: rem(14);
      text-shadow: none;
      border: 0;
      background-color: getColor('primary');
      color: getColor('light');
      &:hover{
        background-color: getColor('secondary');
        color: getColor('primary');
      }
      i {
        display: flex;
        width: rem(7);
      }
      &.driver-disabled {
        display: none !important;
      }
    }
    .driver-prev-btn {
      i {
        margin-right: rem(5);
      }
    }
    .driver-next-btn {
      margin-left: auto;
      i {
        margin-left: rem(5);
      }
    }
    .driver-close-btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: rem(15);
      background-color: transparent;
      border: 0;
      font-size: rem(14);
      color: getColor('smokegray');
      i {
        margin-left: rem(5);
        pointer-events: none;
        svg {
          width: rem(15);
          height: rem(15);
        }
      }
    }
  }
  .step-subtitle {
    color: getColor('smokegray');
    text-transform: uppercase;
    i {
      margin-right: rem(10);
    }
  }
  .step-text {
    margin: rem(10) 0 0 rem(20);
  }
  .step-divider {
    position: relative;
    margin: rem(15) 0 rem(15) rem(15);
    &:after {
      content: '';
      display: block;
      width: 80%;
      height: 1px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background-color: getColor('smokegray');
    }
  }
}

@include media-breakpoint-down(md) {
  div#driver-popover-item {
    min-width: 450px !important;
    max-width: 450px !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}

@include media-breakpoint-down(sm) {
  div#driver-popover-item {
    min-width: 320px !important;
    max-width: 320px !important;
    padding: rem(35) rem(25) rem(25) !important;
  }
}
