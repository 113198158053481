.page-user-area {
  .hero-user-content {
    @extend .hero-dot-pattern;
    margin-top: rem(10);
  }

  .hero-title,
  .hero-description {
    max-width: 470px;
  }

  .hero-title {
    margin-bottom: rem(28);
  }

  .hero-description {
    margin-bottom: rem(35);
  }

  .hero-user-logo {
    display: none;
  }

  .hero-result {
    margin-bottom: rem(26);
    font-size: rem(25);
    font-weight: 500;
    line-height: rem(30);
    &__score {
      font-weight: 600;
    }
  }

  .form-header {
    padding-top: rem(38);
  }

  .form-wrapper {
    background-color: $light;
    .btn {
      width: 100%;
      border-radius: 5px;
      padding: rem(12) rem(20);

      @include media-breakpoint-up("md") {
        max-width: 150px;
      }
    }
  }

  .register-table {
    &__cta {
      display: flex;
      justify-content: flex-end;
      margin-top: rem(10);
      .btn {
        width: 100%;
        border-radius: 5px;
        padding: rem(12) rem(30);
      }
    }
  }
}

@include media-breakpoint-up("lg") {
  .page-user-area {
    .form-header {
      padding-top: rem(38);
    }

    .hero-user-content {
      margin-top: 0;

      &::after {
        content: none;
      }
    }

    .hero-user-logo {
      display: flex;
      svg {
        opacity: 0.05;
      }
    }

    .hero-title {
      margin-top: rem(0);
      margin-bottom: rem(40);
    }

    .hero-description {
      margin-bottom: rem(35);
    }

    .hero-result {
      margin-bottom: rem(20);
    }
  }
}

@include media-breakpoint-up("md") {
  .page-user-area {
    .register-table {
      .btn {
        max-width: 160px;
      }
    }
  }
}
