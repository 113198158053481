.l-course-main{
    //area info component (header hero) & progressbar
    [class*='c-area-info'],
    [class*='c-progressbar']{
        @extend .container;
    }
    .course-main{
        padding-top: 1rem;
        padding-bottom: 1rem;
        //overflow-x: hidden;
        &__container{
            @extend .container;
            margin-top: rem(50);
        }

        &__hero {
            display: flex;
            align-items: center;
            margin-bottom: 3rem;
            @include media-breakpoint-down('md') {
                flex-wrap: wrap;
            }

            //&__image {
            //  @extend .img-fluid;
            //max-width: 140px;
            //width: 100%;
            //its not BEM baby
            //&-wrapper {
            //  margin-right: 2rem;
            //  text-align: center;
            //    //AGAIN? wtf!
            //  @include media-breakpoint-down('md') {
            //    flex: 0 0 100%;
            //    margin-right: 0;
            //    margin-bottom: 1rem;
            //  }
            //}
            //}

            &__title {
                color: $primary;
                font-size: 44px;
                font-weight: 600;
                margin: 0 auto 0 0;

                @include media-breakpoint-down('md') {
                    flex: 0 0 100%;
                    font-size: 32px;
                    margin-bottom: 1rem;
                }

                &-ordinal {
                    &::after {
                        content: ' \2022';
                    }
                }
            }

            &__stats {
                color: #949494;
                font-size: 28px;
                font-weight: 600;
                text-transform: uppercase;

                @include media-breakpoint-down('md') {
                    flex: 0 0 100%;
                    font-size: 20px;
                    margin-bottom: 1rem;
                }
            }
        }

        &__content {
            max-width: $contentMaxWidth;
            margin: 0 auto;
        }

        &__image-wrapper{
            display: block;
            width: 100%;
            border-radius: 5px;
            overflow: hidden;
            img{
                max-width: 100%;
                height: auto;
            }
        }

        //&__image {
        //  @extend .img-fluid;
        //  width: 100%;
        //    //RIP BEM
        //  &-wrapper {
        //    background-color: $white;
        //  }
        //}

        &__description {
            padding: 3rem 0;
            font-size: rem(18);
            line-height: 1.2;
        }

        &__instruction {
            color: getColor('primary');
            border: 1px solid getColor('primary');
            border-radius: 13px;
            padding: 3rem 2rem;
            margin-bottom: 3rem;
            font-size: rem(18);
            font-weight: 600;
            text-align: center;
            display:flex;
            flex-flow: column;
            &__warning{
                font-size: rem(22);
                margin-bottom: rem(20);
                i{
                  line-height: 0;
                  margin-right: rem(7);
                }
            }
            &__msg{
                margin-bottom: rem(20);
            }
            &__link{
                margin-bottom: rem(15);
                color: getColor('text-dark');
            }
        }

        &__button-wrapper{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}


@include media-breakpoint-up("lg") {
    .l-course-main{
        .course-main{
            &__button-wrapper {
                flex-direction: row;

                [class*="cta"] +[class*="cta"]  {
                    margin-top: 0;
                }
            }
        }
    }
}
