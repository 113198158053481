.maintenance-page {
  .header,
  .footer {
    display: none;
  }

  .maintenance__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    &__logo {
      width: 200px;

      svg {
        width: 100%;
      }
    }

    &__text {
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: center;
    }
  }
}
