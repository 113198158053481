.hero {
  &-title {
    color: $primary;
    font-size: rem(40);
    font-weight: 200;
    margin-bottom: rem(16);

    strong {
      font-weight: bold;
    }

    // span {
    //   color: $secondary;
    // }
  }

  &-subtitle {
    color: #a0a0a0;
    font-size: rem(22);
    font-weight: 400;
  }

  &-description {
    color: black;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: rem(20);
  }

  &-result {
    color: #a0a0a0;
    font-size: rem(20);
    font-weight: 600;

    &__score {
      color: $secondary;
    }
  }

  &-action {
    color: $primary;
    font-size: rem(16);
    // font-weight: bold;
    // text-decoration: underline;
    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
}

@include media-breakpoint-up("lg") {
  .hero {
    &-title {
      font-size: rem(40);
    }

    &-subtitle {
      font-size: rem(36);
    }

    &-description {
      font-size: rem(16);
    }

    &-result {
      font-size: rem(30);
    }
  }
}
