.c-login-text-box {
  position: relative;
  padding: rem(50) 0 rem(75);
  background-color: getColor('lightgray');
  overflow: hidden;
  .text-box {
    &__bg {
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      transform: translateX(35%);
      opacity: 0.75;
      svg {
        height: 100%;
        fill: white;
      }
    }
    &__wrapper {
      @include mainContainer;
      position: relative;
      display: flex;
    }
    &__section {
      flex: 0 0 35%;
      padding-left: rem(50);
      &:first-child {
        flex: 0 0 30%;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    &__title {
      font-size: rem(22);
      color: getColor(primary);
      text-align: right;
    }
    &__subtitle {
      font-size: rem(22);
      color: getColor(primary);
    }
    &__body {
      margin-top: rem(20);
      line-height: 1.2;
      color: getColor(text-gray);
    }
  }
}
