$theme-colors: (
  "primary": #e6176d,
  "secondary": #ffffff,
  "tertiary": #78993f,
  "light": #ffffff,
  "lightgray": #f4f4f4,
  "medium": #c6c6c6,
  "smokegray": #9b9b9b,
  "darkgray": #3f4650,
  "darkblue": #222e3d,
  "darkprimary": #06415f,
  "dark": #222222,
  "text": #ffffff,
  "text-dark": #404040,
  "text-gray": #707070,
  "bluegray": #EBF4FF,
  "red": #eb282d,
  "darkred": #b30e12,
  "smoke": #d2d2d2,
  "lightsmoke": #e4e4e4,
  "success": #00a905,
  "warning": #fa6700,
  "error": #b30e12,
  "cell-error": #e68383,
);

// creates classes for color and background with each variant color
@each $color, $val in $theme-colors {
  .bg-#{$color} {
    background-color: $val;
  }
  .color-#{$color} {
    color: $val;
  }
  :root {
    --color-#{$color}: #{$val};
  }
}

@function getColor($key: "primary") {
  @return map-get($theme-colors, $key);
}
