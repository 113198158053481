.c-login-courses {
  .courses {
    &__wrapper {
      padding: rem(50) 0;
      background-color: getColor('secondary');
      overflow: hidden;
    }
    &__container {
      padding-left: calc(#{var(--main-container-pad-x)} * 1px);
    }
    &__divider {
      height: 1px;
      margin-right: calc(#{var(--main-container-pad-x)} * 1px);
      background-color: getColor('medium');
    }
    &__row {
      padding: rem(30) 0;
      display: flex;
    }
    &__info {
      flex: 0 0 30%;
      color: getColor(text-gray);
      &__title {
        margin-bottom: rem(15);
        font-size: rem(25);
        color: getColor(primary);
      }
      &__time,
      &__description {
        display: flex;
        margin-bottom: rem(10);
        font-size: rem(14);
      }
      &__label {
        width: rem(90);
        flex-shrink: 0;
        text-transform: uppercase;
      }
      &__content {
        line-height: 1.2;
        .number {
          font-size: rem(16);
          color: getColor(primary);
        }
      }
    }
    &__cards {
      margin-left: rem(50);
      display: flex;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      transition: all 0.2s;
      user-select: none;
      touch-action: none;
      cursor: grab;
      .c-course-card a:hover {
        cursor: grab;
      }
      &.active {
        transform: scale(1.025);
        cursor: grabbing;
        .c-course-card a:hover {
          cursor: grabbing;
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }
      .c-course-card {
        padding-right: rem(20);
        .card {
          &__img {
            width: rem(350);
            height: rem(175);
          }
          &__badge {
            display: none;
          }
          &__result {
            display: none;
          }
          &__title {
            width: 100%;
            font-size: rem(20);
          }
          &__description {
            font-size: rem(14);
            padding-top: rem(10);
          }
          &__exam {
            display: none;
          }
        }
        &[data-variant='exam'] {
          .card__img-content {
            padding: rem(20);
          }
        }
      }
    }
  }
}
