.c-login-cta-box {
  background-color: getColor('lightgray');
  .cta-box {
    &__wrapper {
      @include mainContainer;
      padding: rem(50) rem(25);
      text-align: center;
    }
    &__title {
      font-size: rem(25);
      color: getColor(primary);
    }
    &__body {
      max-width: 80%;
      font-size: rem(25);
      margin: rem(15) auto rem(30);
      color: getColor(text-gray);
      line-height: 1.2;
    }
    &__cta {
      display: flex;
      justify-content: center;
      .cta-primary {
        width: rem(225);
      }
    }
  }
}
