.c-relator-card {
  padding: rem(30) rem(20);
  display: grid;
  grid-template-columns: 25% 75%;
  .relator {
    &__img {
      img {
        width: 100%;
        border-radius: 50%;
      }
    }
    &__info {
      margin-left: rem(20);
      &__name {
        margin-bottom: rem(10);
        font-weight: 600;
        color: getColor("primary");
      }
      &__bio {
        font-size: rem(14);
        line-height: 1.1rem;
      }
      &__cta {
        margin-top: rem(20);
        font-size: rem(14);
        font-weight: 600;
        a {
          color: black;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down("sm") {
  .c-relator-card {
    display: block;
    .relator {
      &__img {
        text-align: center;
        margin-bottom: rem(10);
        img {
          width: 45%;
        }
      }
    }
  }
}
