.page-admin-area {
  .hero {
    &-user-content {
      @extend .hero-dot-pattern;
      margin-top: rem(10);
    }

    &-title,
    &-description {
      max-width: 470px;
    }

    &-title {
      color: getColor("text-dark");
      margin-bottom: rem(28);
    }

    &-description {
      margin-bottom: rem(35);
    }

    &-result {
      margin-bottom: rem(26);
      font-size: rem(25);
      font-weight: 500;
      line-height: rem(30);
      &__score {
        font-weight: 600;
      }
    }

    &-users {
      display: grid;
      grid-template-columns: repeat(2, 35%);
      column-gap: rem(40);
      &-type {
        font-weight: bold;
        padding-bottom: rem(5);
        border-bottom: 2px solid getColor("primary");
      }
      &-number {
        padding-top: rem(5);
      }
    }
    &-user-logo {
      svg {
        display: none;
      }
      .cta-primary {
        width: 100%;
        font-size: 0.9rem;
        font-weight: bold;
        margin-bottom: rem(10);
        &:hover {
          background: transparent;
        }
      }
    }
  }

  .admin-table-modules {
    background-color: $light;
    .admin-table {
      &__element {
        padding: rem(25) rem(20);
      }
      &__small {
        padding: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
      }
      &__label {
        grid-column: 1 / -1;
      }
      &__value {
        grid-column: 1 / -1;
        justify-content: center;
      }
      &__course,
      &__podcast {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__type {
          font-size: rem(10);
          text-transform: uppercase;
          color: getColor('smokegray');
        }
      }
      &__course {
        background-color: #FFF0F0;
      }
      &__podcast {
        background-color: #E2F4C9;
      }
    }
  }

  .admin-table-register {
    background-color: $white;
    .admin-table {
      &__small {
        .admin-table__value {
          justify-content: center;
        }
      }
    }
  }
}

@include media-breakpoint-up("lg") {
  .page-admin-area {
    .hero {
      &-user-content {
        margin-top: 0;
      }

      &-user-logo {
        display: flex;
        svg {
          display: block;
          opacity: 0.05;
        }
      }

      &-title {
        margin-top: rem(0);
        margin-bottom: rem(40);
      }

      &-description {
        margin-bottom: rem(35);
      }

      &-result {
        margin-bottom: rem(20);
      }
    }
    .admin-table-modules {
      .admin-table {
        &__small {
          grid-template-rows: repeat(2, 1fr);
        }
        &__block,
        &__header {
          grid-template-columns: 24% 35% 21% 20%;
        }
      }
    }
  }
}

// @include media-breakpoint-up(xl) {
@media screen and (min-width: 1200px) {
  .page-admin-area {
    .admin-table-register {
      .admin-table {
        max-width: 1300px;
        padding-left: 25px;
        padding-right: 25px;
        &__block,
        &__header {
          grid-template-columns: 35px 2fr 1fr calc(95px * 9);
        }

        &__header {
          .admin-table__row:first-child {
            .admin-table__super-label {
              border: 0;
            }
          }
        }

        &__super-label-course {
          font-size: rem(12);
          font-weight: 800;
        }

        &__element {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: rem(20) rem(10);
        }

        &__email,
        &__name {
          font-size: rem(14);
        }

        &__email {
          word-break: break-all;
        }

        &__small {
          align-items: center;
        }
      }
    }
  }
}

// @include media-breakpoint-down(lg) {
@media screen and (max-width: 1199px) {
  .page-admin-area {
    .admin-table-register {
      .admin-table {
        &__arrow-left,
        &__arrow-right {
          display: none;
        }

        &__title {
          flex-direction: column;
          align-items: flex-start;
        }

        &__filter {
          width: 100%;
          margin-top: rem(20);
        }

        &__header {
          display: none;
        }

        &__block {
          margin-bottom: rem(10);
        }

        &__label {
          display: block;
        }

        &__label-course {
          text-align: center;
          font-size: rem(12);
          font-weight: 800;
        }

        &__row-boxes {
          width: auto;

          .admin-table__label {
            border: 0;
          }
          .admin-table__element {
            border-bottom: 1px solid $medium;
          }
        }

        &__slider {
          grid-template-columns: repeat(3, 1fr) !important;
          .admin-table__element:first-child {
            grid-column: 1 / -1;
          }
        }

        &__cta {
          display: flex;
          justify-content: center;
          .admin-table__value.icon {
            display: none;
          }
          .admin-table__value.text {
            display: block;
          }
        }

        &__small {
          border-right: 1px solid $medium;
        }

        &__row {
          border-top: 1px solid $medium;
          border-left: 1px solid $medium;
          .admin-table__small:last-child {
            border-right: 0;
          }
        }

        &__row:first-child {
          .admin-table__element:first-child {
            border-left: 0;
          }
        }

        &__element {
          border-top: 0;
          border-bottom: 0;
        }

        &__row:last-child {
          border-bottom: 1px solid $medium;
        }

        &__row-boxes {
          border-top: 1px solid $medium;
          border-left: 1px solid $medium;
        }
      }
    }
  }
}

@include media-breakpoint-down("sm") {
  .page-admin-area {
    .hero-user-cta {
      width: 100%;
    }
  }
}
