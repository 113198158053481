button[type='submit'],
button[type='button'],
button[type='reset'],
button {
    border: none;
    outline: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

%cta{
    --cta-border-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: rem(40);
    font-size: 1rem;
    min-width: rem(130);
    padding: 0 rem(20);
    background-color: var(--cta-bg-color);
    color: currentColor;
    border-radius: rem(4);
    transition: background-color .3s ease-in-out;
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
    &.round{
        border-radius: rem(22);
    }
    &.wide{
        width: 100%;
    }
    &.stroked{
        border: 1px solid currentColor;
    }
    &[disabled]{
        opacity: .6;
        color: getColor('smoke');
        background: getColor('lightgray');
        border-color: getColor('lightgray');
        pointer-events: none;
    }
}

.cta-primary{
    @extend %cta;
    background-color: getColor('primary');
    color: getColor('light');
    &:hover{
        background-color: getColor('secondary');
        color: getColor('primary');
    }
}
.cta-secondary{
    @extend %cta;
    background-color: getColor('secondary');
    color: getColor('primary');
    &:hover{
        background-color: getColor('primary');
        color: getColor('light');
    }
}

