.l-project {
  .c-area-info {
    min-height: auto;
    margin-bottom: 0;
    .area-info__content {
      padding-bottom: 0;
    }
  }

  .project-description {
    &__logo {
      svg {
        width: 100%;
        height: 100%;
      }
    }
    &__subtitle {
      margin-bottom: rem(25);
      color: getColor("primary");
      font-size: rem(20);
      line-height: 1.5rem;
    }
    &__paragraph {
      margin-bottom: rem(25);
      line-height: 1.2rem;
      p {
        margin-bottom: rem(10);
        a {
          color: black;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    &__note {
      text-align: right;
      font-size: rem(12);
      color: getColor("smokegray");
      font-style: italic;
    }
  }
}
