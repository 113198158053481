:root {
  --main-header-height: 70px;
}

.header {
  height: var(--main-header-height);

  &__top-menu {
    padding: 0 1rem;
    background-color: $white;
    display: flex;
    align-items: center;
    height: 70px;
    border-bottom: solid 1px $medium;

    &--fixed {
      z-index: $z-i-header;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 70px;
    }

    &__brand {
      padding: rem(15) 0;
      margin-right: auto;
      display: flex;
      align-items: center;
      &__link {
        display: block;
      }

      &__image {
        max-height: rem(40);
        height: 100%;
        width: auto;
      }

      &__page-title {
        margin-left: rem(25);
        display: flex;
        align-items: center;
        a,
        span {
          color: getColor("darkgray");
          font-size: rem(16);
          &:first-letter {
            text-transform: uppercase;
          }
          &:hover {
            text-decoration: none;
          }
          &:last-child {
            color: getColor("primary");
            &:before {
              content: "/";
              color: getColor("darkgray");
              margin: 0 rem(5);
            }
            &:empty {
              &:before {
                content: "";
              }
            }
          }
        }
      }
    }

    &-md {
      height: 100%;
      display: flex;

      @include media-breakpoint-down("md") {
        display: none;
      }

      &__item {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: rem(15);
      }

      &__link {
        display: flex;
        align-items: center;
        height: rem(45);
        padding: rem(10) rem(20);
        font-weight: 700;
        color: getColor('primary');
        border: 1px solid $medium;
        border-radius: rem(50);
        &-login {
          display: flex;
          align-items: center;
          height: rem(45);
          border: 1px solid $medium;
          border-radius: rem(50);
          overflow: hidden;
          &-info {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 rem(20);
            font-weight: 700;
            color: $primary;
            border-right: 1px solid transparent;
            &:hover {
              color: $white;
              background-color: $primary;
              border-color: $white;
              cursor: pointer;
            }
          }
          &-cta {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 rem(20);
            font-weight: 700;
            color: $white;
            background-color: $primary;
            border-left: 1px solid transparent;
            &:hover {
              color: $primary;
              background-color: $white;
              border-color: $medium;
              cursor: pointer;
            }
          }
          &-icon {
            margin-left: 1rem;
          }
        }
        &-icon {
          color: $medium;
        }

        &:hover {
          color: $white;
          background-color: $primary;
          border-color: $primary;
          text-decoration: none;
          cursor: pointer;

          .header__top-menu-md__link-icon {
            color: $white;
          }
        }

        &-text {
          margin-left: 1rem;
        }
      }

      &__item--active &__link {
        color: $white;
        background-color: $primary;
        border-color: $primary;

        &-icon {
          color: $white;
        }
      }

      &__user-icon {
        color: $white;
        background-color: $primary;
        padding: rem(15);
        border-radius: 50%;
      }

      &__item__user-dropdown {
        position: absolute;
        width: rem(150);
        background-color: $white;
        border-radius: rem(12);
        box-shadow: 0 0 5px $dark;
        top: 95%;
        right: 0;

        .header__user-dropdown__item {
          color: $dark;
          padding: rem(20);
          display: flex;
          align-items: center;

          &__icon {
            margin-right: rem(12);
          }

          &:nth-child(2) {
            border-top: 1px solid $medium;
          }
        }
      }

      &__item__user-dropdown--closed {
        display: none;
      }
    }

    // MOBILE
    &-sm {
      @include media-breakpoint-up("lg") {
        display: none;
      }

      position: fixed;
      top: 0;
      left: 100%;
      right: -100%;
      bottom: 0;
      background-color: $white;
      z-index: 100;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      transition: transform 500ms;

      &--expanded {
        transform: translateX(-100%);
      }

      &__items {
        width: calc(100% - 70px);
        height: 100vh;
        overflow: scroll;
      }

      &__item {
        border-bottom: 1px solid $medium;

        .arrow-up {
          display: block;
        }

        .arrow-down {
          display: none;
        }

        &:last-child {
          border-bottom: none;
        }

        &__link {
          display: flex;
          align-items: center;
          padding: rem(20);
          color: inherit;
          &:hover {
            color: inherit;
          }
        }

        &__icon {
          max-width: rem(35);
          margin-right: rem(20);
          color: $medium;
        }

        &__modules {
          display: flex;
          flex-direction: column;
          background-color: $light;
          padding: rem(20) 0;
        }

        &__module {
          color: $dark;
          padding: rem(15) rem(30);
        }

        &.js-header-mobile {
          color: $primary;
          .header__top-menu-sm__item__link {
            display: flex;
            justify-content: space-between;
          }
          .header__top-menu-sm__item__icon {
            margin: 0;
          }
        }

        &--closed {
          .header__top-menu-sm__item__modules,
          .arrow-up {
            display: none;
          }
          .arrow-down {
            display: block;
          }
        }
      }

      &__close {
        width: 70px;
        height: 100vh;
        background-color: $dark;
        display: flex;
        justify-content: center;
        padding-top: rem(20);
        color: $white;
      }
    }

    &__toggler {
      padding: rem(15) 0 rem(15) rem(15);

      @include media-breakpoint-up("lg") {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down("md") {
  .header {
    &__top-menu {
      &__brand {
        &__page-title {
          display: block;
          span {
            display: block;
            &:last-child {
              &:before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
