.page-register {
  .hero-title {
    margin-top: rem(23);
    margin-bottom: rem(25);
  }

  .hero-title,
  .hero-description {
    max-width: 470px;
  }
}

.register {
  &-tos {
    display: flex;
    flex-direction: column;
    // padding-top: rem(30);

    .form-input {
      margin-bottom: rem(25);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-up("lg") {
  .page-register {
    .hero-title {
      margin-top: rem(0);
      margin-bottom: rem(20);
    }
  }

  .register {
    &-tos {
      .form-input {
        margin-bottom: rem(40);
      }
    }
  }
}
