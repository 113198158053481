.asset {
  &-header {
    display: flex;
    justify-content: space-between;
    padding: rem(6) rem(13);
    border-bottom: thin solid #949494;

    &__title {
      font-size: rem(22);
      color: $secondary;
    }
  }

  &-media {
    display: flex;
    padding: rem(25) 0;
    border-bottom: thin solid #C3C3C3;

    &.wrap {
      flex-wrap: wrap;
    }

    &:last-child {
      border: 0;
    }

    &__title {
      font-size: rem(18);
      font-weight: 600;
      color: $primary;
      margin-bottom: rem(10);

      &:hover {
        color: $primary;
      }
    }

    &__description {
      font-size: rem(14);
      color: black;
      margin-bottom: rem(17);
    }

    &__size {
      font-size: rem(14);
      font-weight: 600;
      color: #949494;
      text-transform: uppercase;
      padding-left: rem(25);
    }

    &__content {
      display: flex;
      flex-direction: column;
      padding-left: rem(25);
    }

    &__download,
    &__video {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__download {
      &-cover {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 80px;
        height: 80px;
        padding: rem(20);
        background-color: white;
      }

      &-img {
        width: 100%;
        height: 100%;
      }

      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &__video {
      &-cover,
      &-content {
        width: 100%;
      }

      &-cover {
        justify-content: center;
        margin-bottom: rem(30);
      }

      &-embedded {
        position: relative;
        padding-top: 56.25%; /* Fixed 16/9 ratio */

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
        }
      }

      &-length {
        position: absolute;
        bottom: rem(8);
        padding: rem(4);
        color: white;
        background: #0000009c;
        border-radius: 4px;
        font-size: rem(14);
        right: 50%;
        transform: translateX(115px);
      }

      &-content {
        .asset-media__content {
          padding-left: 0;
        }
      }
    }
  }
}

@include media-breakpoint-up('lg') {
  .asset {
    &-header {
      padding: rem(6) rem(13);

      &__title {
        font-size: rem(32);
        font-weight: 600;
      }
    }

    &-media {
      flex-wrap: nowrap !important;

      &__title {
        font-size: rem(22);
      }

      &__size {
        font-size: rem(16);
      }

      &__download {
        &-cover {
          width: 140px;
          height: 140px;
          padding: rem(40);
        }
      }

      &__video {
        &-cover {
          flex-shrink: 1.5;
          margin-bottom: 0;
        }

        &-length {
          right: rem(8);
          transform: none;
        }

        &-content {
          .asset-media__content {
            padding-left: rem(35);
          }
        }
      }

      &__content,
      &__size {
        padding-left: rem(35);
      }
    }
  }
}
