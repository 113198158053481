.l-topics {
  //display: flex;
  //flex-flow: row nowrap;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 30px;
  margin-top: rem(70);
  &__explore {
    width: 100%;
    order: 1;
    padding: rem(10);

    &__title {
      font-size: rem(20);
      margin-bottom: rem(30);
    }

    &__searchbar {
      display: flex;
      align-items: center;

      border: solid 1px $dark;
      border-radius: rem(25);
      padding: rem(2) rem(25);
      margin-bottom: rem(15);

      &__icon {
        width: rem(20);
        height: rem(20);
        margin-right: rem(20);
      }

      input[name=search] {
        display: block;
        flex: 1;
        border: none;
        padding: rem(15) rem(5);
        margin: 0;
      }
    }

    &__topics {
      border: solid 1px $dark;
      border-radius: rem(20);
      padding: rem(30);

      &__title {
        font-size: rem(20);
        margin-bottom: rem(20);
      }

      &__cloud {
        display: flex;
        flex-flow: row wrap;
        [class*='cta-']{
          margin: 0 rem(5) rem(5) 0;
        }
      }

      &__clouditem {
        display: block;
        color: $primary;
        border: solid 1px $primary;
        border-radius: rem(20);
        padding: rem(12);
        margin: rem(5);

        &--selected {
          color: $light;
          background-color: $primary;
        }
      }
    }
  }

  &__main {
    //flex: 1;
    &__current{
      position: relative;
      border-left: 2px solid getColor('primary');
      background: getColor('lightsmoke');
      margin-bottom: rem(30);
      color: getColor('text-dark');
      font-size: rem(18);
      p{
        padding: rem(30);
      }
      span{
        text-transform: uppercase;
      }
      a{
        color: currentColor;
        text-decoration: underline;
      }
    }

    &__ask {
      padding: rem(10);

      &__topic {
        display: block;
        border: solid 1px $dark;
        background: $light;
        color: $dark;
        border-radius: rem(10);
        padding: rem(15) rem(5);
        margin-bottom: rem(20);
      }

      //&__text {
      //  display: block;
      //  width: 100%;
      //  height: rem(120);
      //
      //  border: solid 1px $dark;
      //  padding: rem(15) rem(5);
      //  border-radius: rem(10);
      //
      //  margin-bottom: rem(20);
      //}

      //&__submit-wrapper {
      //  width: 100%;
      //  display: flex;
      //  flex-flow: row nowrap;
      //}

      //&__submit {
      //  display: block;
      //
      //  margin-left: auto;
      //
      //  border: none;
      //  border-radius: rem(10);
      //
      //  color: $light;
      //  background: $primary;
      //
      //  padding: rem(15) rem(20);
      //
      //  font-weight: bold;
      //
      //}
    }

    &__comment-separator {
      font-size: rem(24);
      border-bottom: solid 2px $dark;
      padding: rem(15) rem(0);
      margin-top: rem(30);
    }

    &__posts {
      margin-bottom: rem(70);
      &__title {
        color: $dark;
        margin-bottom: rem(15);
      }
    }
  }
}

@include media-breakpoint-up('lg') {
  .l-topics {
    //grid-template-columns: repeat(2,1fr);
    &__explore {
      //max-width: rem(500);
      //order: 1;
    }
  }
}

@include media-breakpoint-down('md') {
  .l-topics {
    //flex-flow: column;
    grid-template-columns: repeat(2,1fr);
    &__explore {
      order: -1;
    }
  }
}

@include media-breakpoint-down('sm'){
  .l-topics{
    grid-template-columns: 1fr;
  }

}
