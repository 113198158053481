.page-invite {
  .invite-container {
    position: relative;
    .login__logos {
      position: fixed;
      width: 100%;
      padding: rem(15);
      display: none;
      justify-content: space-around;
      align-items: center;
      z-index: $z-i-main;
    }
    .static-values {
      margin-bottom: rem(25);
    }
    .static-value {
      width: 100%;
      display: flex;
      font-size: rem(14);
      margin-bottom: rem(10);
      &__label {
        width: rem(80);
        color: getColor(smokegray);
      }
    }
    .invite-section {
      &::before {
        content: '';
        display: block;
        height: var(--main-header-height);
        margin-top: calc(var(--main-header-height) * -1);
        position: relative;
        visibility: hidden;
        z-index: -1;
      }
      display: block;
      &__logo,
      &__close {
        display: none;
      }
      .hero-user-container {
        .hero-user-logo {
          max-height: rem(300);
          svg {
            opacity: 0.05;
          }
        }
      }
      .form-wrapper {
        background-color: $light;
        line-height: 1.2;
        .form-section {
          .form-header {
            justify-content: flex-end;
            &__title {
              display: none;
            }
          }
          .form-input {
            display: block;
            input[name="privacy"] {
              opacity: 0;
              visibility: hidden;
            }
          }
          .form-group {
            width: 100%;
            margin-bottom: 0;
          }
          .register-input__error {
            color: getColor(primary);
            margin-top: rem(5);
            font-size: rem(14);
          }
          .privacy-label {
            padding-left: 0;
          }
          .btn {
            max-width: rem(150);
            border-radius: 5px;
            padding: rem(12) rem(20);
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .page-invite {
    overflow-y: hidden;
    header,
    footer {
      display: none;
    }
    .invite-container {
      width: 100vw;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      .login__logos {
        display: flex;
      }
      .invite-section {
        &::before {
          display: none;
          height: 0;
          margin-top: 0;
        }
        display: none;
        position: absolute;
        width: 100vw;
        height: calc(var(--vh, 1vh) * 100);
        top: 0;
        left: 0;
        overflow: scroll;
        z-index: $z-i-modal;
        background-color: getColor(secondary);
        &.show {
          display: block;
        }
        &__logo {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(-10px, -4px);
          img {
            width: rem(70);
          }
        }
        &__close {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          padding: rem(15);
          color: getColor(smokegray);
          z-index: $z-i-main;
        }
        .hero-user-bg {
          display: none;
        }
        .form-wrapper {
          background-color: transparent;
          .form-section {
            .form-header {
              padding-top: rem(75);
              &__title {
                display: block;
                text-align: center;
                margin-bottom: rem(10);
              }
              &__info {
                text-align: right;
              }
            }
            .form-group {
              > div {
                padding-left: 0;
                padding-right: 0;
              }
            }
            .form-fields {
              padding-bottom: rem(20);
            }
            .static-values {
              margin-bottom: rem(10);
            }
            .form-cta {
              justify-content: center;
              padding-bottom: rem(30);
              .btn {
                max-width: rem(150);
                border-radius: rem(50);
              }
            }
          }
        }
      }
    }
  }
}
