.register-table-bg {
  background: $light;
}

.register-table {
  @extend .container;
  padding-top: rem(40);
  padding-bottom: rem(60);

  &__title {
    color: $primary;
    font-size: rem(25);
    font-weight: 600;
    margin-bottom: rem(40);
  }

  &__block {
    margin: rem(20) 0;
    background: $white;
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  &__element {
    padding: rem(20);
  }

  &__date,
  &__module,
  &__unit {
    grid-column: 1 / 5;
  }

  &__label {
    display: block;
    color: $medium;
    font-size: rem(12);
    text-transform: uppercase;
  }

  &__value {
    font-weight: 500;
    line-height: 1.5rem;

    a:hover {
      color: $primary;
    }
  }

  @include media-breakpoint-up(lg) {
    &__block {
      display: grid;
      grid-template-columns: 15% 40% 22.5% 22.5%;
    }

    &__row:last-child {
      .register-table__element:last-child {
        border-right: 1px solid $medium;
      }
    }

    &__element {
      border-top: 1px solid $medium;
      border-bottom: 1px solid $medium;
      border-left: 1px solid $medium;
    }
  }

  @include media-breakpoint-down(md) {
    &__row {
      border-top: 1px solid $medium;
      border-left: 1px solid $medium;
      border-right: 1px solid $medium;

      &:last-child {
        border-bottom: 1px solid $medium;
      }
    }

    &__hour {
      border-right: 1px solid $medium;
      &:last-child {
        border: 0;
      }
    }
  }
}
