.hidden{
  display: none !important;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@include media-breakpoint-up('lg') {
  .hero-dot-pattern {
    &::after {
      background: url("/images/dot_pattern_desktop.svg");
      background-size: 12%;
    }
  }
}

.hero-dot-pattern {
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("/images/dot_pattern_mobile.svg");
    z-index: -1;
  }
}

.hero-dot-pattern-v2 {
  &::after {
    position: absolute;
    content: "";
    left: 0;
    transform: translateX(0);
    top: 0;
    width: 100vw;
    height: 100%;
    background: url("/images/dot_pattern_mobile.svg");
    z-index: -1;
  }
}

@include media-breakpoint-up('lg') {
  .hero-dot-pattern-v2 {
    &::after {
      background: url("/images/dot_pattern_desktop.svg");
      left: 50%;
    }
  }
}
