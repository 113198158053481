.c-form-ask-forum{
  margin-bottom: rem(30);
  .form-ask{
    &__title {
      font-size: rem(25);
      margin-bottom: rem(30);
    }
    &__select{
      width: min(500px,100%);
    }
    &__submit{
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
