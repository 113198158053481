.hero {
  &-courses {
    &__background {
      position: relative;
      margin-top: 1rem;

      &:after {
        position: absolute;
        top: 0;
        left: 50%;
        right: 0;
        bottom: 0;
        z-index: -1;
        background-image: url('/images/dot_pattern_desktop.svg');
        background-repeat: repeat;
        background-position: top left;

        @include media-breakpoint-up('md') {
          content: '';
        }
      }
    }

    &__container {
      @extend .container;
      padding-top: 3rem;
      padding-bottom: 3rem;
    }

    &__row {
      @extend .row;
      align-items: center;
    }

    &__logo {
      @extend .col-md-4;
      position: relative;
      text-align: center;
      padding-bottom: 1rem;

      &-bg {
        @extend .img-fluid;

        @include media-breakpoint-up('md') {
          max-height: 100%;
          height: 100%;
          width: auto;
        }

        &-wrapper {
          z-index: -1;
          text-align: right;

          @include media-breakpoint-up('md') {
            position: absolute;
            top: 0;
            bottom: 0;
            left: -100%;
            right: -2.5rem;
          }
        }
      }

      &-image {
        @extend .img-fluid;

        @include media-breakpoint-down('sm') {
          max-height: 100%;
          height: 100%;
          width: auto;
        }

        &-wrapper {
          padding-top: 2rem;
          padding-bottom: 2.75rem;

          @include media-breakpoint-down('sm') {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 1rem;
          }
        }
      }
    }

    &__content {
      @extend .col-md-8;
      padding-bottom: 1rem;
    }

    &__title {
      color: $primary;
      font-size: 34px;

      @include media-breakpoint-up('md') {
        font-size: 50px;
      }
    }

    &__description {
      color: $black;

      @include media-breakpoint-up('md') {
        font-size: 23px;
      }
    }

    &__points {
      color: #A0A0A0;
      font-size: 20px;
      font-weight: 600;
      margin-top: 1rem;

      @include media-breakpoint-up('md') {
        font-size: 30px;
      }
    }
  }
}
